//
//
//
//
//
import nutCollapseItem from './collapse-item';
export default {
  name: 'nut-collapse',
  components: {
    nutCollapseItem: nutCollapseItem
  },
  model: {
    prop: 'value',
    event: 'change-active'
  },
  props: {
    value: {
      type: String | Number
    },
    accordion: {
      type: Boolean
    },
    expandIconPosition: {
      type: String,
      default: 'right'
    },
    icon: {
      type: String,
      default: ''
    },
    rotate: {
      type: Number | String,
      default: 180
    }
  },
  watch: {
    value: function value(newVal, oldVal) {
      this.accordionFun(newVal);
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    changeEvt: function changeEvt(name) {
      this.$parent.change(name);
    },
    changeValAry: function changeValAry(name) {
      var index = -1;
      this.value.forEach(function (item, idx) {
        if (String(item) == String(name)) {
          index = idx;
        }
      });
      var v = JSON.parse(JSON.stringify(this.value));
      index > -1 ? v.splice(index, 1) : v.push(name);
      this.$emit('change-active', v);
    },
    changeVal: function changeVal(val) {
      this.$emit('change-active', val);
    },
    // 手风琴模式将所有的item收起，然后对应的展开（默认）
    // 对于展开的再次点击的将其设置成收起，动画效果在item组件中执行
    accordionFun: function accordionFun(val) {
      if (val instanceof Array) {} else {
        this.$children.forEach(function (item) {
          if (item.name == val && item.openExpanded) {
            item.changeOpen(false);
          } else {
            item.name == val ? item.changeOpen(true) : item.changeOpen(false);
            item.animation();
          }
        });
      }
    }
  }
};