//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-toast',
  props: {},
  data: function data() {
    return {
      id: '',
      msg: '',
      visible: false,
      duration: 2000,
      //显示时间(毫秒)
      timer: null,
      center: true,
      type: '',
      customClass: '',
      bottom: 30,
      size: 'base',
      icon: null,
      textAlignCenter: true,
      loadingRotate: true,
      bgColor: 'rgba(46, 46, 46, 0.7)',
      onClose: null,
      textTimer: null,
      cover: false,
      coverColor: 'rgba(0, 0, 0, 0)',
      toastStyle: {},
      toastClass: '',
      timeStamp: null,
      closeOnClickOverlay: false
    };
  },
  watch: {
    visible: function visible(val) {
      if (val) {
        this.show();
      }
    }
  },
  computed: {
    cusIcon: function cusIcon() {
      return this.icon ? "url(\"".concat(this.icon, "\")") : '';
    },
    toastBodyClass: function toastBodyClass() {
      return ['nut-toast', {
        'nut-toast-center': this.center
      }, {
        'nut-toast-has-icon': this.hasIcon
      }, {
        'nut-toast-cover': this.cover
      }, {
        'nut-loading': this.type == 'loading'
      }, this.customClass, 'nut-toast-' + this.size];
    },
    hasIcon: function hasIcon() {
      if (this.type !== 'text') {
        return true;
      } else {
        return this.cusIcon;
      }
    }
  },
  methods: {
    show: function show(force) {
      var _this = this;

      this.clearTimer();
      clearTimeout(this.textTimer);

      if (this.duration) {
        this.timer = setTimeout(function () {
          _this.hide(force);
        }, this.duration);
      }
    },
    hide: function hide(force) {
      var _this2 = this;

      this.clearTimer();
      this.visible = false;

      if (force) {
        clearTimeout(this.textTimer);
      } else {
        this.textTimer = setTimeout(function () {
          clearTimeout(_this2.textTimer);
          _this2.msg = '';
        }, 300);
      }

      typeof this.onClose === 'function' && this.onClose();
    },
    clearTimer: function clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    clickCover: function clickCover() {
      if (this.closeOnClickOverlay) {
        this.hide();
      }
    }
  },
  destroyed: function destroyed() {
    this.textTimer = null;
    this.timer = null;
  }
};