//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-actionsheet',
  props: {
    isAnimation: {
      type: Boolean,
      default: true
    },
    isLockBgScroll: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    isShowMask: {
      type: Boolean,
      default: true
    },
    isClickChooseClose: {
      type: Boolean,
      default: true
    },
    isClickCloseMask: {
      type: Boolean,
      default: true
    },
    cancelTxt: {
      type: String,
      default: ''
    },
    optionTag: {
      type: String,
      default: 'name'
    },
    chooseTagValue: {
      type: String,
      default: ''
    },
    menuItems: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  },
  watch: {
    isVisible: function isVisible(value) {
      !!value && this.$emit('open');

      if (this.isLockBgScroll) {
        if (value) {
          document.body.classList.add('nut-overflow-hidden');
        } else {
          this.removeLockScrool();
        }
      }
    }
  },
  deactivated: function deactivated() {
    this.removeLockScroll();
  },
  destroyed: function destroyed() {
    this.removeLockScroll();
  },
  methods: {
    removeLockScroll: function removeLockScroll() {
      document.body.classList.remove('nut-overflow-hidden');
    },
    isHighlight: function isHighlight(item) {
      return this.chooseTagValue && this.chooseTagValue == item[this.optionTag] || this.chooseTagValue === 0;
    },
    closeActionSheet: function closeActionSheet() {
      this.$emit('close');
    },
    cancelActionSheet: function cancelActionSheet() {
      this.closeActionSheet();
      this.$emit('cancel');
    },
    clickActionSheetMask: function clickActionSheetMask() {
      this.isClickCloseMask && this.closeActionSheet();
    },
    chooseItem: function chooseItem(item, index) {
      if (!item.disable) {
        if (this.isClickChooseClose) {
          this.closeActionSheet();
        }

        this.$emit('choose', item, index);
      }
    }
  }
};