//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-skeleton',
  data: function data() {
    return {};
  }
};