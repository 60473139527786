import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { version } from '../package.json';
import { packages as pkgList } from './config.json';
import { locale } from './locales';
import Cell from './packages/cell/index.js';
import './packages/cell/cell.scss';
import Dialog from './packages/dialog/index.js';
import './packages/dialog/dialog.scss';
import Icon from './packages/icon/index.js';
import './packages/icon/icon.scss';
import Toast from './packages/toast/index.js';
import './packages/toast/toast.scss';
import ActionSheet from './packages/actionsheet/index.js';
import './packages/actionsheet/actionsheet.scss';
import Tab from './packages/tab/index.js';
import './packages/tab/tab.scss';
import TabPanel from './packages/tabpanel/index.js';
import './packages/tabpanel/tabpanel.scss';
import TabBar from './packages/tabbar/index.js';
import './packages/tabbar/tabbar.scss';
import Calendar from './packages/calendar/index.js';
import './packages/calendar/calendar.scss';
import DatePicker from './packages/datepicker/index.js';
import './packages/datepicker/datepicker.scss';
import NavBar from './packages/navbar/index.js';
import './packages/navbar/navbar.scss';
import NoticeBar from './packages/noticebar/index.js';
import './packages/noticebar/noticebar.scss';
import Switch from './packages/switch/index.js';
import './packages/switch/switch.scss';
import Slider from './packages/slider/index.js';
import './packages/slider/slider.scss';
import Range from './packages/range/index.js';
import './packages/range/range.scss';
import Picker from './packages/picker/index.js';
import './packages/picker/picker.scss';
import Progress from './packages/progress/index.js';
import './packages/progress/progress.scss';
import Price from './packages/price/index.js';
import './packages/price/price.scss';
import Flex from './packages/flex/index.js';
import './packages/flex/flex.scss';
import Col from './packages/col/index.js';
import './packages/col/col.scss';
import Row from './packages/row/index.js';
import './packages/row/row.scss';
import Steps from './packages/steps/index.js';
import './packages/steps/steps.scss';
import Button from './packages/button/index.js';
import './packages/button/button.scss';
import Badge from './packages/badge/index.js';
import './packages/badge/badge.scss';
import Rate from './packages/rate/index.js';
import './packages/rate/rate.scss';
import Swiper from './packages/swiper/index.js';
import './packages/swiper/swiper.scss';
import Menu from './packages/menu/index.js';
import './packages/menu/menu.scss';
import Stepper from './packages/stepper/index.js';
import './packages/stepper/stepper.scss';
import ButtonGroup from './packages/buttongroup/index.js';
import './packages/buttongroup/buttongroup.scss';
import SearchBar from './packages/searchbar/index.js';
import './packages/searchbar/searchbar.scss';
import ImagePicker from './packages/imagepicker/index.js';
import './packages/imagepicker/imagepicker.scss';
import Radio from './packages/radio/index.js';
import './packages/radio/radio.scss';
import RadioGroup from './packages/radiogroup/index.js';
import './packages/radiogroup/radiogroup.scss';
import CheckBox from './packages/checkbox/index.js';
import './packages/checkbox/checkbox.scss';
import CheckBoxGroup from './packages/checkboxgroup/index.js';
import './packages/checkboxgroup/checkboxgroup.scss';
import ShortPassword from './packages/shortpassword/index.js';
import './packages/shortpassword/shortpassword.scss';
import Skeleton from './packages/skeleton/index.js';
import './packages/skeleton/skeleton.scss';
import Scroller from './packages/scroller/index.js';
import './packages/scroller/scroller.scss';
import BackTop from './packages/backtop/index.js';
import './packages/backtop/backtop.scss';
import CountDown from './packages/countdown/index.js';
import './packages/countdown/countdown.scss';
import InfiniteLoading from './packages/infiniteloading/index.js';
import './packages/infiniteloading/infiniteloading.scss';
import Uploader from './packages/uploader/index.js';
import './packages/uploader/uploader.scss';
import TextInput from './packages/textinput/index.js';
import './packages/textinput/textinput.scss';
import Avatar from './packages/avatar/index.js';
import './packages/avatar/avatar.scss';
import Lazyload from './packages/lazyload/index.js';
import './packages/textbox/textbox.scss';
import TextBox from './packages/textbox/index.js';
import Elevator from './packages/elevator/index.js';
import './packages/elevator/elevator.scss';
import Popup from './packages/popup/index.js';
import LeftSlip from './packages/leftslip/index.js';
import './packages/leftslip/leftslip.scss';
import TabSelect from './packages/tabselect/index.js';
import './packages/tabselect/tabselect.scss';
import './packages/popup/popup.scss';
import LuckDraw from './packages/luckdraw/index.js';
import './packages/luckdraw/luckdraw.scss';
import Video from './packages/video/index.js';
import './packages/video/video.scss';
import Signature from './packages/signature/index.js';
import './packages/signature/signature.scss';
import CircleProgress from './packages/circleprogress/index.js';
import './packages/circleprogress/circleprogress.scss';
import TimeLine from './packages/timeline/index.js';
import './packages/timeline/timeline.scss';
import TimeLineItem from './packages/timelineitem/index.js';
import './packages/timelineitem/timelineitem.scss';
import SideNavBar from './packages/sidenavbar/index.js';
import './packages/sidenavbar/sidenavbar.scss';
import SubSideNavBar from './packages/subsidenavbar/index.js';
import './packages/subsidenavbar/subsidenavbar.scss';
import SideNavBarItem from './packages/sidenavbaritem/index.js';
import './packages/sidenavbaritem/sidenavbaritem.scss';
import Drag from './packages/drag/index.js';
import './packages/drag/drag.scss'; // import VueQr from "./packages/qart/index.js";
// import "./packages/qart/qart.scss";

import Address from './packages/address/index.js';
import './packages/address/address.scss';
import Notify from './packages/notify/index.js';
import './packages/notify/notify.scss';
import CountUp from './packages/countup/index.js';
import './packages/countup/countup.scss';
import FixedNav from './packages/fixednav/index.js';
import './packages/fixednav/fixednav.scss'; // import Gesture from './packages/gesture/index.js';
// import './packages/gesture/gesture.scss';

import Collapse from './packages/collapse/index.js';
import './packages/collapse/collapse.scss';
import Luckycard from "./packages/luckycard/index.js";
import "./packages/luckycard/luckycard.scss";
import NumberKeyboard from './packages/numberkeyboard/index.js';
import './packages/numberkeyboard/numberkeyboard.scss';
var packages = {
  Cell: Cell,
  Dialog: Dialog,
  Icon: Icon,
  Toast: Toast,
  ActionSheet: ActionSheet,
  Tab: Tab,
  TabPanel: TabPanel,
  TabBar: TabBar,
  Calendar: Calendar,
  DatePicker: DatePicker,
  NavBar: NavBar,
  NoticeBar: NoticeBar,
  Switch: Switch,
  Slider: Slider,
  Range: Range,
  Picker: Picker,
  Progress: Progress,
  Price: Price,
  Flex: Flex,
  Col: Col,
  Row: Row,
  Steps: Steps,
  Button: Button,
  Badge: Badge,
  Rate: Rate,
  Swiper: Swiper,
  Menu: Menu,
  Stepper: Stepper,
  ButtonGroup: ButtonGroup,
  SearchBar: SearchBar,
  ImagePicker: ImagePicker,
  Radio: Radio,
  RadioGroup: RadioGroup,
  CheckBox: CheckBox,
  CheckBoxGroup: CheckBoxGroup,
  ShortPassword: ShortPassword,
  Skeleton: Skeleton,
  Scroller: Scroller,
  BackTop: BackTop,
  CountDown: CountDown,
  InfiniteLoading: InfiniteLoading,
  Uploader: Uploader,
  TextInput: TextInput,
  TextBox: TextBox,
  Avatar: Avatar,
  Elevator: Elevator,
  Popup: Popup,
  LeftSlip: LeftSlip,
  TabSelect: TabSelect,
  LuckDraw: LuckDraw,
  Video: Video,
  Signature: Signature,
  CircleProgress: CircleProgress,
  TimeLine: TimeLine,
  TimeLineItem: TimeLineItem,
  SideNavBar: SideNavBar,
  SubSideNavBar: SubSideNavBar,
  SideNavBarItem: SideNavBarItem,
  Drag: Drag,
  Address: Address,
  Notify: Notify,
  CountUp: CountUp,
  FixedNav: FixedNav,
  // Gesture: Gesture
  Collapse: Collapse,
  Luckycard: Luckycard,
  NumberKeyboard: NumberKeyboard
};
var components = {};
var methods = {};
var filters = {};
var directives = {};
pkgList.map(function (item) {
  var pkg = packages[item.name];
  if (!pkg) return;

  if (item.type == 'component') {
    if (pkg.name) {
      components[pkg.name] = pkg;
    } else {
      for (var n in pkg) {
        components[n] = pkg[n];
      }
    }
  } else if (item.type == 'method') {
    methods[item.name] = pkg;
  } else if (item.type == 'filter') {
    filters[item.name] = pkg;
  } else if (item.type == 'directive') {
    directives[item.name] = pkg;
  }
});

var install = function install(Vue) {
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (install.installed) return;

  if (opts.locale) {
    Vue.config.lang = opts.locale;
  }

  if (opts.lang) locale(Vue.config.lang, opts.lang);

  for (var cptName in methods) {
    if (Array.isArray(methods[cptName])) {
      Vue.prototype['$' + cptName.toLowerCase()] = methods[cptName][0];
      Vue.component(methods[cptName][1].name, methods[cptName][1]);
    } else {
      Vue.prototype['$' + cptName.toLowerCase()] = methods[cptName];
    }
  }

  for (var _cptName in components) {
    if (components[_cptName] && components[_cptName].name) {
      Vue.component(components[_cptName].name, components[_cptName]);
    }
  }

  for (var _cptName2 in filters) {
    if (filters[_cptName2] && filters[_cptName2].name) {
      Vue.filter(_cptName2, filters[_cptName2]);
    }
  }

  for (var _cptName3 in directives) {
    if (directives[_cptName3] && directives[_cptName3].name) {
      Vue.directive(directives[_cptName3].name, directives[_cptName3]);
    }
  }

  Vue.use(Lazyload, {
    lazyComponent: true,
    loading: '//img12.360buyimg.com/imagetools/jfs/t1/73967/28/14561/916/5dc142e4E0666555b/bf33454553c6035e.png'
  });
};

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default _objectSpread(_objectSpread(_objectSpread(_objectSpread({
  version: version,
  locale: locale,
  install: install,
  Lazyload: Lazyload
}, components), filters), directives), methods);