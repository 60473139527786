//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import locale from '../../mixins/locale';
export default {
  name: 'nut-shortpassword',
  mixins: [locale],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: 'javascript:;'
    },
    type: {
      type: String,
      default: 'bottom'
    }
  },
  data: function data() {
    return {
      shortpwdValue: '',
      regExp: /^[0-9]*$/,
      // fakeCursorStyle:{
      // 	display:'none',
      // 	left:'0px'
      // },
      fakeInputItems: [{
        id: 1,
        val: ''
      }, {
        id: 2,
        val: ''
      }, {
        id: 3,
        val: ''
      }, {
        id: 4,
        val: ''
      }, {
        id: 5,
        val: ''
      }, {
        id: 6,
        val: ''
      }],
      timer: null,
      isCenter: this.type == 'bottom' ? false : true,
      layerTran: this.type == 'bottom' ? 'popup' : 'fade',
      show: false
    };
  },
  watch: {
    visible: function visible(val, oldVal) {
      var _this = this;

      if (!val) {
        clearTimeout(this.timer);
        this.timer = setTimeout(function () {
          _this.show = val;
        }, 100);
      } else {
        this.show = val;
      }
    }
  },
  methods: {
    closeLayer: function closeLayer() {
      var _this2 = this;

      this.timer = setTimeout(function () {
        _this2.$emit('close');
      }, 300);
    },
    shortpwdInput: function shortpwdInput(e) {
      var _this3 = this;

      var val = e.target.value;

      if (this.regExp.test(val)) {
        //位移假光标
        if (val.length < 6) {
          var num = this.$refs.nutFakeList.offsetLeft + 26;
          var left = val.length * 54 + num; // this.fakeCursorStyle = {
          // 	'left':left+'px',
          // 	'display':'block'
          // }
        } else {
          // this.fakeCursorStyle = {
          // 	'display':'none'
          // }
          this.$refs.realInput.blur();
          this.timer = setTimeout(function () {
            //如果是6位输入满，将直接关闭弹层，给出输入的值
            _this3.$emit('close', val);

            _this3.shortpwdValue = '';
            _this3.fakeInputItems = [{
              id: 1,
              val: ''
            }, {
              id: 2,
              val: ''
            }, {
              id: 3,
              val: ''
            }, {
              id: 4,
              val: ''
            }, {
              id: 5,
              val: ''
            }, {
              id: 6,
              val: ''
            }];
          }, 300);
        }

        for (var i = 0; i < val.length; i++) {
          this.fakeInputItems[i].val = val[i];
        }
      } else {
        //清空非数字
        this.shortpwdValue = val.replace(/\D/g, '');
      } //删除操作的时候同步假密码框


      for (var i = 0; i < 6; i++) {
        if (i >= val.length) {
          this.fakeInputItems[i].val = '';
        }
      }
    }
  },
  computed: {},
  mounted: function mounted() {},
  destroyed: function destroyed() {
    this.timer = null;
  }
};