//
//
//
//
//
//
//
export default {
  name: 'nut-switch',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'base'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      isActive: false,
      arrLabel: (this.label || '').split('|')
    };
  },
  created: function created() {
    this.isActive = this.active;
  },
  watch: {
    active: function active(newVal) {
      this.isActive = newVal;
    }
  },
  methods: {
    toggle: function toggle() {
      var _this = this;

      var status = this.isActive;

      if (!this.disabled) {
        this.isActive = !status;
        setTimeout(function () {
          _this.$emit('change', _this.isActive);

          _this.$emit('update:active', _this.isActive);
        }, 300);
      }
    }
  }
};