//
//
//
//
//
export default {
  name: 'nut-price',
  props: {
    price: {
      type: [Number, String],
      default: 0
    },
    needSymbol: {
      type: Boolean,
      default: true
    },
    decimalDigits: {
      type: [Number, String],
      default: 2
    },
    thousands: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    priceShow: function priceShow() {
      var self = this;
      var symbol = self.needSymbol ? '<span class="price-symbol">￥</span>' : '';
      return symbol + self.formatToHump(self.price);
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    //判断是否为小数点
    checkPoint: function checkPoint(num) {
      return String(num).indexOf('.') > 0;
    },
    //将数字转换成驼峰形式
    formatToHump: function formatToHump(num) {
      var self = this;
      num = String(num).replace('￥', '');

      if (self.checkPoint(num)) {
        var numArray = Number(num).toFixed(this.decimalDigits);
        numArray = String(numArray).split('.');
        return '<span class="price-big">' + self.formatThousands(numArray[0]) + '</span><span class="price-point">.</span><span class="price-small">' + self.formatDecimal(numArray[1]) + '</span>';
      } else {
        return '<span class="price-big">' + self.formatThousands(num) + '</span><span class="price-point">.</span><span class="price-small">' + self.formatDecimal(0) + '</span>';
      }
    },
    //根据小数位数格式化小数部分
    formatDecimal: function formatDecimal(decimalNum) {
      var self = this;
      var decimalDigits = self.decimalDigits;
      var result = '0.' + String(decimalNum);
      var resultFixed = (result / 1).toFixed(decimalDigits);
      return String(resultFixed).substring(2, resultFixed.length);
    },
    //千分位显示
    formatThousands: function formatThousands(num) {
      var self = this;
      var result = ''; // let

      if (self.thousands) {
        return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      } else {
        return num;
      }
    }
  }
};