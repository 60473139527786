//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
function fill2(v) {
  v += '';

  while (v.length < 2) {
    v = '0' + v;
  }

  return v;
}

function restTime(t) {
  var ts = t;
  var rest = {
    d: '-',
    h: '--',
    m: '--',
    s: '--'
  };

  if (ts === 0) {
    rest = {
      d: '0',
      h: '00',
      m: '00',
      s: '00'
    };
  }

  if (ts) {
    var ds = 24 * 60 * 60 * 1000;
    var hs = 60 * 60 * 1000;
    var ms = 60 * 1000;
    var d = ts >= ds ? parseInt(ts / ds) : 0;
    var h = ts - d * ds >= hs ? parseInt((ts - d * ds) / hs) : 0;
    var m = ts - d * ds - h * hs >= ms ? parseInt((ts - d * ds - h * hs) / ms) : 0;
    var s = Math.round((ts - d * ds - h * hs - m * ms) / 1000);
    if (d >= 0) rest.d = d + '';
    if (h >= 0) rest.h = fill2(h);
    if (m >= 0) rest.m = fill2(m);
    if (s >= 0) rest.s = fill2(s);
  }

  return rest;
}

var countdownTimer = {
  name: 'nut-countdown',
  data: function data() {
    return {
      restTime: 0,
      p: 0,
      _curr: 0
    };
  },
  props: {
    paused: {
      default: false,
      type: Boolean
    },
    showDays: {
      default: false,
      type: Boolean
    },
    showPlainText: {
      default: false,
      type: Boolean
    },
    startTime: {
      // 可以是服务器当前时间
      type: [Number, String],
      validator: function validator(v) {
        var dateStr = new Date(v).toString().toLowerCase();
        return dateStr !== 'invalid date';
      }
    },
    endTime: {
      type: [Number, String],
      validator: function validator(v) {
        var dateStr = new Date(v).toString().toLowerCase();
        return dateStr !== 'invalid date';
      }
    }
  },
  computed: {
    resttime: function resttime() {
      var rest = restTime(this.restTime);
      var d = rest.d,
          h = rest.h,
          m = rest.m,
          s = rest.s;

      if (!this.showDays && d > 0) {
        rest.h = fill2(Number(rest.h) + d * 24);
        rest.d = 0;
      }

      return rest;
    },
    plainText: function plainText() {
      var _this$resttime = this.resttime,
          d = _this$resttime.d,
          h = _this$resttime.h,
          m = _this$resttime.m,
          s = _this$resttime.s;
      return "".concat(d > 0 && this.showDays ? d + '天' + h : h, "\u5C0F\u65F6").concat(m, "\u5206").concat(s, "\u79D2");
    }
  },
  watch: {
    paused: function paused(v, ov) {
      if (!ov) {
        this._curr = this.getTimeStamp();
        this.$emit('on-paused', this.restTime);
      } else {
        this.p += this.getTimeStamp() - this._curr;
        this.$emit('on-restart', this.restTime);
      }
    },
    endTime: function endTime() {
      this.initTimer();
    },
    startTime: function startTime() {
      this.initTimer();
    }
  },
  methods: {
    getTimeStamp: function getTimeStamp(timeStr) {
      if (!timeStr) return Date.now();
      var t = timeStr;
      t = t > 0 ? +t : t.toString().replace(/\-/g, '/');
      return new Date(t).getTime();
    },
    initTimer: function initTimer() {
      var _this = this;

      var delay = 1000;
      var curr = Date.now();
      var start = this.getTimeStamp(this.startTime || curr);
      var end = this.getTimeStamp(this.endTime || curr);
      var diffTime = curr - start;
      this.restTime = end - (start + diffTime);
      this.timer = setInterval(function () {
        if (!_this.paused) {
          var _restTime = end - (Date.now() - _this.p + diffTime);

          _this.restTime = _restTime;

          if (_restTime < delay) {
            _this.restTime = 0;

            _this.$emit('on-end');

            clearInterval(_this.timer);
          }
        } else {// 暂停
        }
      }, delay);
    }
  },
  created: function created() {
    this.initTimer();
  },
  destroyed: function destroyed() {
    this.timer && clearInterval(this.timer);
  }
};
countdownTimer.restTime = restTime; // export fill2 for test

export { restTime, fill2 };
export default countdownTimer;