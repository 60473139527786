import _typeof from "@babel/runtime/helpers/typeof";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-checkbox',
  props: {
    name: {
      type: String
    },
    size: {
      type: [String, Number, Boolean],
      default: 'base'
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      required: true
    },
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
    submittedValue: {
      type: String,
      default: 'on' // HTML default

    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    animation: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value: function value() {
      this.isChecked = this.looseEqual(this.value, this.trueValue);
    },
    checked: function checked(newValue, oldValue) {
      this.isChecked = newValue;
    }
  },
  data: function data() {
    return {
      isChecked: this.value == this.trueValue || this.checked
    };
  },
  mounted: function mounted() {
    this.$emit('update:checked', this.isChecked ? this.trueValue : this.falseValue, this.label);
    this.$emit('input', this.isChecked ? this.trueValue : this.falseValue, this.label);
  },
  methods: {
    isObject: function isObject(obj) {
      return obj !== null && _typeof(obj) === 'object';
    },
    looseEqual: function looseEqual(a, b) {
      return a == b || (this.isObject(a) && this.isObject(b) ? JSON.stringify(a) === JSON.stringify(b) : false);
    },
    changeEvt: function changeEvt(event) {
      var isCheckedPrevious = this.isChecked;
      var isChecked = event.target.checked;
      this.$emit('input', isChecked ? this.trueValue : this.falseValue, this.label, event);

      if (isCheckedPrevious !== isChecked) {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue, this.label, event);
      }
    }
  }
};