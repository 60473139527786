import _defineProperty from "@babel/runtime/helpers/defineProperty";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import nuticon from '../icon/icon.vue';
import locale from '../../mixins/locale';
export default {
  name: 'nut-searchbar',
  mixins: [locale],
  props: {
    hasIcon: {
      type: Boolean,
      default: false
    },
    searchIconSize: {
      type: String,
      default: '20px'
    },
    searchIconColor: {
      type: String,
      default: '#2e2d2d'
    },
    searchBtnIconSize: {
      type: String,
      default: '20px'
    },
    searchBtnIconColor: {
      type: String,
      default: '#2e2d2d'
    },
    clearIconSize: {
      type: String,
      default: '15px'
    },
    clearIconColor: {
      type: String,
      default: '#2e2d2d'
    },
    placeText: {
      type: String
    },
    hasSearchButton: {
      type: Boolean,
      default: true
    },
    hasTextButton: {
      type: Boolean,
      default: false
    },
    textInfo: {
      type: String
    },
    animation: {
      type: Boolean,
      default: true
    },
    customClass: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  components: _defineProperty({}, nuticon.name, nuticon),
  data: function data() {
    return {
      inputFocusAnimation: false
    };
  },
  watch: {
    value: function value(newValue, oldValue) {
      this.updateValue('change');
    }
  },
  computed: {
    hasCloseIcon: function hasCloseIcon() {
      return this.value ? true : false;
    }
  },
  mounted: function mounted() {},
  methods: {
    updateValue: function updateValue() {
      var trigger = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'input';
      var searchInputValue = trigger == 'change' ? this.value : this.$refs.searchInput.value;
      this.$emit(trigger, searchInputValue);
    },
    //清空 input 输入
    clearInput: function clearInput() {
      this.$emit('clear', '');
      this.$emit('input', '');
    },
    focusFun: function focusFun() {
      this.inputFocusAnimation = true;
      this.$emit('focus');
    },
    inputFun: function inputFun() {
      this.updateValue();
    },
    blurFun: function blurFun() {
      this.inputFocusAnimation = false;
      this.updateValue('blur');
    },
    submitFun: function submitFun() {
      this.updateValue('submit');
    },
    // 失去焦点
    blur: function blur() {
      this.$refs.searchInput.blur();
    },
    //js控制获取焦点
    focus: function focus() {
      this.$nextTick(function () {
        this.$refs.searchInput.focus();
      });
    }
  }
};