//
//
//
//
//
//
//
//
//
//
//
var overlayProps = {
  value: {
    type: Boolean,
    default: false
  },
  overlay: {
    type: Boolean,
    default: true
  },
  lockScroll: {
    type: Boolean,
    default: true
  },
  duration: {
    type: Number,
    default: 0.3
  },
  closeOnClickOverlay: {
    type: Boolean,
    default: true
  },
  overlayClass: {
    type: String,
    default: ''
  },
  overlayStyle: {
    type: Object,
    default: function _default() {}
  },
  zIndex: {
    type: Number
  }
};
export { overlayProps };
export default {
  name: 'nut-popup-overlay',
  props: overlayProps,
  methods: {
    touchmove: function touchmove(e) {
      if (this.lockScroll) {
        e.preventDefault();
      }
    }
  }
};