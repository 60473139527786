//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-timelineitem',
  props: {
    // 轴点的样式：circle 圆  hollow 空心
    pointType: {
      type: String,
      default: 'circle'
    },
    // 轴点的颜色
    pointColor: {
      type: String,
      default: '#fa2e05'
    }
  },
  data: function data() {
    return {
      isCustomPoint: false //是否自定义轴点

    };
  },
  computed: {
    pointClass: function pointClass() {
      return this.pointType + '-icon';
    },
    pointStyle: function pointStyle() {
      return {
        borderColor: this.pointColor,
        background: this.pointType == 'circle' ? this.pointColor : 'transparent'
      };
    },
    isShowTitle: function isShowTitle() {
      return this.$slots.title ? true : false;
    }
  },
  mounted: function mounted() {
    this.isCustomPoint = this.$slots.dot ? true : false;
  },
  methods: {}
};