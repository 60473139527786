//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-sidenavbar',
  props: {
    offset: {
      type: [String, Number],
      default: 15
    }
  },
  mounted: function mounted() {
    this.handleSlots();
    this.observer = new MutationObserver(function (mutations) {
      this.count = 1;
      this.handleSlots();
    }.bind(this));
    this.observer.observe(this.$refs.list, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: false
    });
  },
  data: function data() {
    return {
      count: 1,
      observer: null
    };
  },
  methods: {
    handleSlots: function handleSlots() {
      var slots = this.$slots.default;

      if (slots) {
        slots = slots.filter(function (item) {
          return item.elm.nodeType !== 3;
        }).map(function (item) {
          return item.elm;
        });
        this.setPaddingLeft(slots);
      }
    },
    setPaddingLeft: function setPaddingLeft(nodeList) {
      var level = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

      for (var i = 0; i < nodeList.length; i++) {
        var item = nodeList[i];
        item.children[0].style.paddingLeft = this.offset * level + 'px';

        if (!item.className.includes('nut-sidenavbaritem')) {
          this.setPaddingLeft(Array.from(item.children[1].children), ++this.count);
        }
      }

      this.count--;
    }
  }
};