//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import './rate.scss';
export default {
  name: 'nut-rate',
  props: {
    total: {
      type: [String, Number],
      default: 5
    },
    value: {
      type: [String, Number],
      default: 3
    },
    size: {
      type: [String, Number],
      default: 25
    },
    uncheckedIcon: {
      type: String,
      default: null
    },
    checkedIcon: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    spacing: {
      type: [String, Number],
      default: 20
    }
  },
  data: function data() {
    return {
      current: 3
    };
  },
  created: function created() {
    this.current = this.value;
  },
  methods: {
    onClick: function onClick($event, idx) {
      if (this.readOnly) {
        this.$emit('input', this.current);
        this.$emit('click', this.current);
      } else {
        this.current = idx;
        this.$emit('input', idx);
        this.$emit('click', idx);
      }
    }
  },
  watch: {
    value: function value(newVal, oldVal) {
      this.current = newVal;
    }
  }
};