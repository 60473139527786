//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-subsidenavbar',
  props: {
    title: {
      type: String,
      default: ''
    },
    ikey: {
      type: [String, Number],
      default: ''
    },
    open: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      direction: ''
    };
  },
  mounted: function mounted() {
    this.direction = this.open ? '' : 'up';
  },
  methods: {
    handleClick: function handleClick() {
      this.$emit('titleClick');
      this.direction = !this.direction ? 'up' : '';
    }
  }
};