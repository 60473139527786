//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CheckBox from '../checkbox/checkbox.vue';
import Button from '../button/button.vue';
import ButtonGroup from '../buttongroup/buttongroup.vue';
import Icon from '../icon/icon.vue';
import locale from '../../mixins/locale';

var lockMaskScroll = function (bodyCls) {
  var scrollTop;
  return {
    afterOpen: function afterOpen() {
      scrollTop = document.scrollingElement.scrollTop || document.body.scrollTop;
      document.body.classList.add(bodyCls);
      document.body.style.top = -scrollTop + 'px';
    },
    beforeClose: function beforeClose() {
      if (document.body.classList.contains(bodyCls)) {
        document.body.classList.remove(bodyCls);
        document.scrollingElement.scrollTop = scrollTop;
      }
    }
  };
}('dialog-open');

export default {
  name: 'nut-menu',
  mixins: [locale],
  components: {
    'nut-button': Button,
    'nut-button-group': ButtonGroup,
    'nut-checkbox': CheckBox,
    'nut-icon': Icon
  },
  props: {
    isAnimation: {
      //是否有动画效果
      type: Boolean,
      default: false
    },
    isVisible: {
      //是否显示
      type: Boolean,
      default: false
    },
    type: {
      //单选 simple  多选  multiple
      type: String,
      default: 'simple'
    },
    col: {
      //显示的列数
      type: [Number, String],
      default: 1
    },
    mulstyle: {
      //多选样式,支持三种多选样式 style1 style2 style3
      type: [String],
      default: 'style1'
    },
    max: {
      type: [String, Number],
      default: 5
    },
    custom: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    isVisible: function isVisible(val) {
      lockMaskScroll[val ? 'afterOpen' : 'beforeClose']();
    }
  },
  data: function data() {
    return {
      datalist: []
    };
  },
  mounted: function mounted() {
    this.datalist = this.list;
  },
  computed: {
    okBtnTxt: function okBtnTxt() {
      return this.nutTranslate('lang.okBtnTxt');
    },
    resetBtnTxt: function resetBtnTxt() {
      return this.nutTranslate('lang.menu.resetBtnTxt');
    }
  },
  methods: {
    clickMenuMask: function clickMenuMask() {
      this.$emit('close');
    },
    selectedItem: function selectedItem(item, index) {
      this.$emit('choose', item, index);
    },
    selectMutiple: function selectMutiple(index) {
      if (!this.datalist[index].selected) {
        //判断设置的最大可选项
        var count = 0;
        this.datalist.forEach(function (item) {
          if (item.selected) count++;
        });

        if (count >= this.max) {
          this.$emit('maxTip');
          return;
        }
      }

      this.$set(this.datalist[index], 'selected', !this.datalist[index].selected);
    },
    resetMenu: function resetMenu() {
      this.datalist.map(function (value, key) {
        return value.selected = false;
      });
      this.$emit('reset', this.datalist);
    },
    sureMenu: function sureMenu() {
      this.$emit('close');
      this.$emit('ok', this.datalist);
    }
  }
};