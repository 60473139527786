//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import findCptUpward from '../../mixins/findCptUpward/index.js';
export default {
  name: 'nut-radio',
  mixins: [findCptUpward],
  props: {
    value: {
      type: [String, Number, Boolean],
      default: false
    },
    label: [String, Number, Boolean],
    size: {
      type: String,
      default: 'base'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    animated: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      parent: null
    };
  },
  computed: {
    currentValue: {
      get: function get() {
        return this.parent ? this.parent.value : this.value;
      },
      set: function set(val) {
        (this.parent || this).$emit('input', val);
      }
    },
    currentSize: function currentSize() {
      return this.parent ? this.parent.size || this.size : this.size;
    },
    isDisabled: function isDisabled() {
      return this.parent ? this.parent.disabled || this.disabled : this.disabled;
    },
    isAnimated: function isAnimated() {
      return this.parent ? this.parent.animated && this.animated : this.animated;
    }
  },
  created: function created() {
    this.findCptUpward('nut-radiogroup');
  },
  methods: {
    clickEvt: function clickEvt(event) {
      if (this.isDisabled) {
        return false;
      }

      this.currentValue = this.label;
    }
  }
};