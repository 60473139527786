//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-collapse-item',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: Number | String,
      default: -1,
      required: true
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      var active = _this.$parent && _this.$parent.value;

      if (typeof active == 'number' || typeof active == 'string') {
        if (_this.name == active) {
          _this.defaultOpen();
        }
      }

      if (active instanceof Array) {
        var f = active.filter(function (item) {
          return item == _this.name;
        });

        if (f.length > 0) {
          _this.defaultOpen();
        }
      }
    });
    this.classDirection = this.$parent.expandIconPosition;

    if (this.$parent.icon) {
      this.$set(this.iconStyle, 'background-image', 'url(' + this.$parent.icon + ')');
    }
  },
  data: function data() {
    return {
      openExpanded: false,
      classDirection: 'right',
      iconStyle: {
        'background-image': 'url(https://img10.360buyimg.com/imagetools/jfs/t1/111306/10/17422/341/5f58aa0eEe9218dd6/28d76a42db334e31.png)',
        'background-repeat': 'no-repeat',
        'background-size': '100% 100%',
        transform: 'rotate(0deg)'
      }
    };
  },
  methods: {
    defaultOpen: function defaultOpen() {
      this.$parent.accordion ? this.$parent.accordionFun(this.name) : this.open();

      if (this.$parent.icon) {
        this.$set(this.iconStyle, 'transform', 'rotate(' + this.$parent.rotate + 'deg)');
      }
    },
    toggleOpen: function toggleOpen() {
      this.$parent.changeEvt(this.name);

      if (this.$parent.accordion) {
        this.$parent.changeVal(this.name);

        if (this.$parent.value == this.name) {
          this.$parent.accordionFun(this.name);
        }

        this.animation();
      } else {
        this.$parent.changeValAry(this.name);
        this.open();
      }
    },
    open: function open() {
      this.openExpanded = !this.openExpanded;
      this.animation();
    },
    // 手风琴模式
    animation: function animation() {
      var _this2 = this;

      this.$nextTick(function () {
        var _this2$$refs = _this2.$refs,
            content = _this2$$refs.content,
            wrapper = _this2$$refs.wrapper;

        if (!content || !wrapper) {
          return;
        }

        var offsetHeight = content.offsetHeight;

        if (offsetHeight) {
          var contentHeight = "".concat(offsetHeight, "px");
          wrapper.style.willChange = 'height';
          wrapper.style.height = !_this2.openExpanded ? 0 : contentHeight;

          if (_this2.$parent.icon && !_this2.openExpanded) {
            _this2.$set(_this2.iconStyle, 'transform', 'rotate(0deg)');
          } else {
            _this2.$set(_this2.iconStyle, 'transform', 'rotate(' + _this2.$parent.rotate + 'deg)');
          }
        }

        if (!_this2.openExpanded) {
          _this2.onTransitionEnd();
        }
      });
    },
    // 更改子组件展示
    changeOpen: function changeOpen(bol) {
      this.openExpanded = bol;
    },
    // 清除 willChange 减少性能浪费
    onTransitionEnd: function onTransitionEnd() {
      this.$refs.wrapper.style.willChange = 'auto';
    }
  }
};