export var passiveSupported = false;

try {
  var options = Object.defineProperty({}, 'passive', {
    get: function get() {
      passiveSupported = true;
    }
  });
  window.addEventListener('test', null, options);
} catch (err) {}

export function on(target, event, handler) {
  var passive = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  target.addEventListener(event, handler, passiveSupported ? {
    capture: false,
    passive: passive
  } : false);
}
export function off(target, event, handler) {
  target.removeEventListener(event, handler);
}