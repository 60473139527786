import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-tab',
  props: {
    isScroll: {
      type: Boolean,
      default: false
    },
    isShowLine: {
      type: Boolean,
      default: true
    },
    defIndex: {
      type: Number,
      default: 0
    },
    positionNav: {
      type: String,
      default: 'top'
    },
    initData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    wrapperHeight: {
      type: [String, Number],
      default: '200'
    },
    lineWidth: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      tabTitleList: [],
      activeIndex: this.defIndex,
      initX: '0',
      navWidth: 0,
      tapWidth: 0
    };
  },
  watch: {
    isScroll: function isScroll() {
      this.updeteTab();
    },
    lineWidth: function lineWidth() {
      this.updeteTab();
    },
    defIndex: function defIndex() {
      this.updeteTab();
    },
    initData: {
      handler: function handler() {
        this.updeteTab();
      },
      deep: true
    }
  },
  computed: {
    //下面有些样式名称是为了兼容之前的版本
    positionNavCss: function positionNavCss() {
      if (this.positionNav === 'left' || this.positionNav === 'right') return true;
    },
    titleClass: function titleClass() {
      if (this.positionNav == 'top') {
        return 'nut-tab-title';
      }

      return 'nut-tab-title-' + this.positionNav + 'nav';
    },
    navBarClass: function navBarClass() {
      if (this.positionNav == 'top') {
        return 'nav-bar';
      }

      return 'nav-bar-' + this.positionNav;
    },
    titleNavList: function titleNavList() {
      if (this.positionNav == 'top' || this.positionNav == 'bottom') {
        if (this.isScroll) {
          return 'nut-title-nav-scroll';
        }

        return 'nut-title-nav-list';
      } else {
        if (this.isScroll) {
          return 'nut-title-vertical-scroll';
        }

        return 'nut-title-nav-' + this.positionNav + 'nav';
      }
    },
    navBarStyle: function navBarStyle() {
      if (this.positionNav === 'top' || this.positionNav === 'bottom') {
        return {
          transform: "translateX(".concat(this.initX, "px)"),
          //width: this.navWidth + 'px'
          width: (this.lineWidth ? this.lineWidth : this.navWidth) + 'px'
        };
      }

      return {
        transform: "translateY(".concat(this.initX, "px)"),
        //height: this.navWidth + 'px'
        height: (this.lineWidth ? this.lineWidth : this.navWidth) + 'px'
      };
    },
    customHeight: function customHeight() {
      if (this.isScroll && (this.positionNav === 'left' || this.positionNav === 'right')) {
        return {
          height: this.wrapperHeight + 'px'
        };
      } else {
        return null;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.$slots.default && _this.updeteTab(_this.$slots.default);
    });
  },
  methods: {
    updeteTab: function updeteTab() {
      var _this2 = this;

      this.$nextTick(function () {
        _this2.tabTitleList = [];
        _this2.activeIndex = _this2.defIndex;

        _this2.initTab(_toConsumableArray(_this2.$slots.default));
      });
    },
    initTab: function initTab(slot) {
      var _this3 = this;

      for (var i = 0; i < slot.length; i++) {
        var slotTag = slot[i].tag;

        if (typeof slotTag == 'string' && slotTag.indexOf('nut-tab-panel') != -1) {
          var attrs = slot[i].data.attrs;
          var item = {
            tabTitle: attrs['tab-title'] || attrs['tabTitle'],
            disable: attrs.disable === false,
            iconUrl: attrs['iconUrl'] || attrs['icon-url'],
            tabSlot: attrs['tab-slot'] || attrs['tabSlot'] || '',
            badge: attrs['badge'] || false
          };
          this.tabTitleList.push(item);
          var slotElm = slot[i].elm;

          if (slotElm) {
            slotElm.classList.add('hide');

            if (this.activeIndex == i) {
              slotElm.classList.remove('hide');
            }
          }
        }
      }

      this.$nextTick(function () {
        var tapWidth;

        if (_this3.positionNav == 'top' || _this3.positionNav == 'bottom') {
          _this3.navWidth = _this3.$refs.navlist.querySelector('.nut-title-nav').offsetWidth;
          tapWidth = _this3.$refs.navlist.offsetWidth;
        } else {
          _this3.navWidth = _this3.$refs.navlist.querySelector('.nut-title-nav').offsetHeight;
          tapWidth = _this3.$refs.navlist.offsetHeight;
        } // this.initX = parseInt(this.navWidth * this.defIndex);
        // this.tapWidth = tapWidth / 2 - this.navWidth / 2;


        if (_this3.lineWidth > 0 && _this3.lineWidth < _this3.navWidth) {
          _this3.initX = parseInt((_this3.navWidth - _this3.lineWidth) / 2 + _this3.navWidth * _this3.defIndex);
        } else {
          _this3.initX = parseInt(_this3.navWidth * _this3.defIndex);
          _this3.tapWidth = tapWidth / 2 - _this3.navWidth / 2;
        }
      });
    },
    findParent: function findParent(event, myclass) {
      var parentCpt = event.target;
      var flag = 0; //避免死循环

      while (parentCpt && flag < 10) {
        flag++;

        if (parentCpt.className && parentCpt.className === myclass) {
          break;
        }

        parentCpt = parentCpt.parentNode;
      }

      return parentCpt;
    },
    switchTab: function switchTab(index, event, disable) {
      if (!disable) {
        this.activeIndex = index; // this.initX = parseInt(this.navWidth * index);

        if (this.lineWidth > 0 && this.lineWidth < this.navWidth) {
          this.initX = parseInt((this.navWidth - this.lineWidth) / 2 + this.navWidth * index);
        } else {
          this.initX = parseInt(this.navWidth * index);
        }

        if (this.positionNav == 'top' || this.positionNav == 'bottom') {
          this.$refs.navlist.scroll(this.initX - this.tapWidth, 0);
        } else {
          this.$refs.navlist.scroll(0, this.initX - this.tapWidth);
        }

        var nutTab = this.findParent(event, 'nut-tab-part');
        var items = this.$refs.items.children;

        for (var i = 0; i < items.length; i++) {
          if (i == index) {
            items[i].classList.remove('hide');
          } else {
            items[i].classList.add('hide');
          }
        }

        this.$emit('tab-switch', index, event);
        this.$emit('tabSwitch', index, event); //兼容以前驼峰法命名
      }
    }
  }
};