//
//
//
//
//
export default {
  name: 'nut-timeline',
  props: {},
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {}
};