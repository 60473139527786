//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-picker-slot',
  props: {
    listData: {
      type: Array,
      required: true
    },
    defaultValue: {
      type: String | Number
    },
    keyIndex: {
      type: Number,
      default: 0
    },
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      touchParams: {
        startY: 0,
        endY: 0,
        startTime: 0,
        endTime: 0
      },
      currIndex: 1,
      transformY: 0,
      scrollDistance: 0,
      lineSpacing: 36,
      rotation: 20,
      timer: null
    };
  },
  watch: {
    isUpdate: function isUpdate() {
      this.transformY = 0;
      this.modifyStatus();
    },
    defaultValue: function defaultValue() {
      this.transformY = 0;
      this.modifyStatus();
    }
  },
  methods: {
    updateTransform: function updateTransform(value) {
      var _this = this;

      if (value) {
        this.transformY = 0;
        this.timer = setTimeout(function () {
          _this.modifyStatus(null, value);
        }, 10);
      }
    },
    setRollerStyle: function setRollerStyle(index) {
      return "transform: rotate3d(1, 0, 0, ".concat(-this.rotation * index, "deg) translate3d(0px, 0px, 104px)");
    },
    isHidden: function isHidden(index) {
      if (index >= this.currIndex + 8 || index <= this.currIndex - 8) {
        return true;
      } else {
        return false;
      }
    },
    setTransform: function setTransform() {
      var translateY = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var type = arguments.length > 1 ? arguments[1] : undefined;
      var time = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1000;
      var deg = arguments.length > 3 ? arguments[3] : undefined;

      if (type === 'end') {
        this.$refs.list.style.webkitTransition = "transform ".concat(time, "ms cubic-bezier(0.19, 1, 0.22, 1)");
        this.$refs.roller.style.webkitTransition = "transform ".concat(time, "ms cubic-bezier(0.19, 1, 0.22, 1)");
      } else {
        this.$refs.list.style.webkitTransition = '';
        this.$refs.roller.style.webkitTransition = '';
      }

      this.$refs.list.style.webkitTransform = "translate3d(0, ".concat(translateY, "px, 0)");
      this.$refs.roller.style.webkitTransform = "rotate3d(1, 0, 0, ".concat(deg, ")");
      this.scrollDistance = translateY;
    },
    setMove: function setMove(move, type, time) {
      var _this2 = this;

      var updateMove = move + this.transformY;

      if (type === 'end') {
        // 限定滚动距离
        if (updateMove > 0) {
          updateMove = 0;
        }

        if (updateMove < -(this.listData.length - 1) * this.lineSpacing) {
          updateMove = -(this.listData.length - 1) * this.lineSpacing;
        } // 设置滚动距离为lineSpacing的倍数值


        var endMove = Math.round(updateMove / this.lineSpacing) * this.lineSpacing;
        var deg = "".concat((Math.abs(Math.round(endMove / this.lineSpacing)) + 1) * this.rotation, "deg");
        this.setTransform(endMove, type, time, deg);
        this.timer = setTimeout(function () {
          _this2.setChooseValue(endMove);
        }, time / 2);
        this.currIndex = Math.abs(Math.round(endMove / this.lineSpacing)) + 1;
      } else {
        var _deg = '0deg';

        if (updateMove < 0) {
          _deg = "".concat((Math.abs(updateMove / this.lineSpacing) + 1) * this.rotation, "deg");
        } else {
          _deg = "".concat((-updateMove / this.lineSpacing + 1) * this.rotation, "deg");
        }

        this.setTransform(updateMove, null, null, _deg);
        this.currIndex = Math.abs(Math.round(updateMove / this.lineSpacing)) + 1;
      }
    },
    setChooseValue: function setChooseValue(move) {
      this.$emit('chooseItem', this.listData[Math.round(-move / this.lineSpacing)], this.keyIndex);
    },
    touchStart: function touchStart(event) {
      event.preventDefault();
      var changedTouches = event.changedTouches[0];
      this.touchParams.startY = changedTouches.pageY;
      this.touchParams.startTime = event.timestamp || Date.now();
      this.transformY = this.scrollDistance;
    },
    touchMove: function touchMove(event) {
      event.preventDefault();
      var changedTouches = event.changedTouches[0];
      this.touchParams.lastY = changedTouches.pageY;
      this.touchParams.lastTime = event.timestamp || Date.now();
      var move = this.touchParams.lastY - this.touchParams.startY;
      this.setMove(move);
    },
    touchEnd: function touchEnd(event) {
      event.preventDefault();
      var changedTouches = event.changedTouches[0];
      this.touchParams.lastY = changedTouches.pageY;
      this.touchParams.lastTime = event.timestamp || Date.now();
      var move = this.touchParams.lastY - this.touchParams.startY;
      var moveTime = this.touchParams.lastTime - this.touchParams.startTime;

      if (moveTime <= 300) {
        move = move * 2;
        moveTime = moveTime + 1000;
        this.setMove(move, 'end', moveTime);
      } else {
        this.setMove(move, 'end');
      }
    },
    modifyStatus: function modifyStatus(type, defaultValue) {
      defaultValue = defaultValue ? defaultValue : this.defaultValue;
      var index = -1;

      if (defaultValue && defaultValue.value) {
        this.listData.some(function (item, idx) {
          if (item.value == defaultValue.value) {
            index = idx;
            return true;
          }
        });
      } else {
        index = this.listData.indexOf(defaultValue);
      }

      this.currIndex = index === -1 ? 1 : index + 1;
      var move = index === -1 ? 0 : index * this.lineSpacing;
      type && this.setChooseValue(-move);
      this.setMove(-move);
    }
  },
  mounted: function mounted() {
    var _this3 = this;

    this.$nextTick(function () {
      _this3.modifyStatus(true); // 监听


      _this3.$el.addEventListener('touchstart', _this3.touchStart);

      _this3.$el.addEventListener('touchmove', _this3.touchMove);

      _this3.$el.addEventListener('touchend', _this3.touchEnd);
    });
  },
  beforeDestroy: function beforeDestroy() {
    // 移除监听
    this.$el.removeEventListener('touchstart', this.touchStart);
    this.$el.removeEventListener('touchmove', this.touchMove);
    this.$el.removeEventListener('touchend', this.touchEnd);
    clearTimeout(this.timer);
  }
};