import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _components;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import nutactionsheet from '../actionsheet/actionsheet.vue';
import '../actionsheet/actionsheet.scss';
import nutpickerslot from './picker-slot.vue';
import locale from '../../mixins/locale';
export default {
  name: 'nut-picker',
  mixins: [locale],
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    customClassName: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ' '
    },
    listData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    defaultValueData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  components: (_components = {}, _defineProperty(_components, nutactionsheet.name, nutactionsheet), _defineProperty(_components, nutpickerslot.name, nutpickerslot), _components),
  data: function data() {
    return {
      chooseValueData: [],
      cacheValueData: [],
      isUpdate: false
    };
  },
  watch: {
    defaultValueData: function defaultValueData() {
      this.chooseValueData = _toConsumableArray(this.defaultValueData);
      this.cacheValueData = _toConsumableArray(this.defaultValueData);
      this.$emit('confirm', this.cacheValueData);
    }
  },
  methods: {
    updateChooseValue: function updateChooseValue(self, index, value) {
      self.cacheValueData.splice(index, 1, value);
      var ref = "picker-slot-".concat(index);
      self.$refs[ref] && self.$refs[ref][0].updateTransform(value);
    },
    closeActionSheet: function closeActionSheet() {
      this.isUpdate = !this.isUpdate;
      this.cacheValueData = _toConsumableArray(this.chooseValueData);
      this.$emit('close');
      this.$emit('close-update', this, this.chooseValueData);
    },
    confirm: function confirm() {
      this.$emit('confirm', this.cacheValueData);
      this.chooseValueData = _toConsumableArray(this.cacheValueData);
      this.$emit('close');
    },
    chooseItem: function chooseItem(value, index) {
      if (this.cacheValueData[index] !== value) {
        this.cacheValueData[index] = value;
        this.$emit('choose', this, index, value, this.cacheValueData);
      }
    }
  },
  created: function created() {
    if (this.defaultValueData && this.defaultValueData.length) {
      this.chooseValueData = _toConsumableArray(this.defaultValueData);
    } else {
      var defaultValueData = [];
      this.listData.map(function (item, index) {
        defaultValueData.push(item[0]);
      });
      this.chooseValueData = [].concat(defaultValueData);
    }
  }
};