//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-textbox',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxNum: {
      type: [String, Number],
      default: 50
    },
    placeText: {
      type: String,
      default: '请您在此输入'
    },
    txtAreaH: {
      type: [String, Number],
      default: '50'
    },
    switchMax: {
      type: Boolean,
      default: false
    },
    textBgColor: {
      type: String,
      default: '#fff'
    },
    limitShow: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      errorState: false,
      txtNum: 0
    };
  },
  mounted: function mounted() {},
  computed: {
    currentValue: {
      get: function get() {
        this.txtNum = this.value.length;

        if (this.txtNum > this.maxNum) {
          this.txtNum = this.maxNum;
          return this.value.substr(0, this.maxNum);
        } else {
          return this.value;
        }
      },
      set: function set(val) {}
    },
    txtAreaHeight: function txtAreaHeight() {
      var txtAreaH;
      txtAreaH = this.txtAreaH;
      return txtAreaH;
    },
    iptMaxlength: function iptMaxlength() {
      var maxlength;

      if (this.switchMax) {
        maxlength = this.maxNum;
      }

      return maxlength;
    }
  },
  methods: {
    txtIptLength: function txtIptLength(event) {
      var data = event.target.value;
      var txtLength = data.length;
      this.txtNum = txtLength;

      if (txtLength > this.maxNum) {
        this.errorState = true;
        this.$emit('errorFunc');
      } else {
        this.errorState = false;
      }

      this.$emit('inputFunc', data);
      this.$emit('input', data);
    }
  }
};