//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Icon from '../icon/icon.vue';
export default {
  name: 'nut-cell',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    isLink: {
      type: Boolean,
      default: false
    },
    linkUrl: {
      type: String,
      default: 'javascript:void(0)'
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: '#fff'
    },
    to: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_self'
    }
  },
  components: {
    'nut-icon': Icon
  },
  data: function data() {
    return {};
  },
  methods: {
    clickCell: function clickCell() {
      this.$emit('click-cell');
    },
    jumpPage: function jumpPage() {
      if (!this.to) return false;
      this.$router.push(this.to);
    }
  }
};