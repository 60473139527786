//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-vert-scroll',
  props: {
    stretch: {
      type: Number,
      default: 100
    },
    isUnMore: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    pulldownTxt: {
      type: String,
      default: '下拉刷新'
    },
    loadMoreTxt: {
      type: String,
      default: '上拉加载'
    },
    unloadMoreTxt: {
      type: String,
      default: '没有更多了'
    },
    threshold: {
      type: Number,
      default: 100
    },
    propsTime: {
      type: Number,
      default: 0
    },
    scrollTo: {
      type: Number,
      default: 1
    }
  },
  watch: {
    isLoading: function isLoading(status) {
      var _this = this;

      if (!status && this.realMove === 0) {
        clearTimeout(this.timer);
        this.timer = setTimeout(function () {
          _this.setTransform(_this.realMove, 'end', null);
        }, this.propsTime);
      }
    },
    isUnMore: function isUnMore() {
      this.isShow();
    },
    scrollTo: function scrollTo(val) {
      if (typeof val === 'number' && !isNaN(val) && val <= 0) {
        this.setTransform(val, null, 500);
        this.$emit('scrollToCbk');
      }
    }
  },
  data: function data() {
    return {
      touchParams: {
        startY: 0,
        endY: 0,
        startTime: 0,
        endTime: 0
      },
      translateY: 0,
      scrollDistance: 0,
      timer: null,
      timerEmit: null,
      realMove: 0,
      isShowLoadMore: false,
      listMinHeightStyle: 'auto',
      isFirstPull: true
    };
  },
  methods: {
    isShow: function isShow() {
      var wrapH = this.$refs.wrapper.offsetHeight;
      var listH = this.$refs.list.offsetHeight;

      if (wrapH < listH) {
        this.isShowLoadMore = true;
        this.listMinHeightStyle = 'auto';
      } else {
        this.isShowLoadMore = false;
        this.isFirstPull = true;
        this.listMinHeightStyle = "".concat(wrapH, "px");
      }
    },
    setTransform: function setTransform() {
      var translateY = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var type = arguments.length > 1 ? arguments[1] : undefined;
      var time = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 500;

      if (type === 'end') {
        this.$refs.list.style.webkitTransition = "transform ".concat(time, "ms cubic-bezier(0.19, 1, 0.22, 1)");
      } else {
        this.$refs.list.style.webkitTransition = '';
      }

      this.$refs.list.style.webkitTransform = "translate3d(0, ".concat(translateY, "px, 0)");
      this.scrollDistance = translateY;
      this.$emit('scrollChange', translateY);
    },
    setMove: function setMove(move, type, time) {
      var _this2 = this;

      var updateMove = move + this.translateY;
      var h = this.$refs.wrapper.offsetHeight;
      var maxMove = -this.$refs.list.offsetHeight + h;

      if (type === 'end') {
        if (updateMove > 0) {
          this.realMove = 0;

          if ((!this.isShowLoadMore || this.isFirstPull) && !this.isLoading && updateMove > 20) {
            updateMove = 50;
            clearTimeout(this.timerEmit);
            this.timerEmit = setTimeout(function () {
              _this2.$emit('pulldown');
            }, time / 2);
          } else {
            this.isFirstPull = true;
            updateMove = 0;
          }
        } else if (updateMove < 0 && updateMove < maxMove + this.threshold) {
          if (updateMove < maxMove) {
            updateMove = maxMove;
          }

          this.realMove = maxMove;

          if (!this.isLoading && !this.isUnMore) {
            //clearTimeout(this.timerEmit);
            //this.timerEmit = setTimeout(() => {
            this.$emit('loadMore'); // }, time / 2);
          }
        } // if (updateMove == 50 && !this.isLoading) {
        //     clearTimeout(this.timer);
        //     this.timer = setTimeout(() => {
        //         this.setTransform(this.realMove, 'end', null);
        //     }, 3000);
        // }


        this.setTransform(updateMove, type, time);
      } else {
        if (updateMove > 0 && updateMove > this.stretch) {
          updateMove = this.stretch;
        } else if (updateMove < maxMove - this.stretch) {
          updateMove = maxMove - this.stretch;
        }

        this.setTransform(updateMove, null, null);
      }
    },
    touchStart: function touchStart(event) {
      // event.preventDefault();
      var changedTouches = event.changedTouches[0];
      this.touchParams.startY = changedTouches.pageY;
      this.touchParams.startTime = event.timestamp || Date.now();
      this.translateY = this.scrollDistance;
    },
    touchMove: function touchMove(event) {
      event.preventDefault();
      var changedTouches = event.changedTouches[0];
      this.touchParams.lastY = changedTouches.pageY;
      this.touchParams.lastTime = event.timestamp || Date.now();
      var move = this.touchParams.lastY - this.touchParams.startY;

      if (move < 0 && this.isShowLoadMore && this.isFirstPull) {
        this.isFirstPull = false;
      }

      this.setMove(move);
    },
    touchEnd: function touchEnd(event) {
      // event.preventDefault();
      var changedTouches = event.changedTouches[0];
      this.touchParams.lastY = changedTouches.pageY;
      this.touchParams.lastTime = event.timestamp || Date.now();
      var move = this.touchParams.lastY - this.touchParams.startY;
      var moveTime = this.touchParams.lastTime - this.touchParams.startTime;
      var h = this.$refs.wrapper.offsetHeight;
      var maxMove = -this.$refs.list.offsetHeight + h;

      if (moveTime <= 300) {
        move = move * 2;

        if (move < 0 && move < maxMove) {
          move = maxMove;
        }

        moveTime = moveTime + 500;
        this.setMove(move, 'end', moveTime);
      } else {
        this.setMove(move, 'end');
      }
    }
  },
  mounted: function mounted() {
    var _this3 = this;

    this.$nextTick(function () {
      _this3.isShow(); // 监听


      _this3.$el.addEventListener('touchstart', _this3.touchStart);

      _this3.$el.addEventListener('touchmove', _this3.touchMove);

      _this3.$el.addEventListener('touchend', _this3.touchEnd);
    });
  },
  beforeDestroy: function beforeDestroy() {
    // 移除监听
    this.$el.removeEventListener('touchstart', this.touchStart);
    this.$el.removeEventListener('touchmove', this.touchMove);
    this.$el.removeEventListener('touchend', this.touchEnd);
    clearTimeout(this.timer);
    clearTimeout(this.timerEmit);
  }
};