//
//
//
//
//
export default {
  name: 'nut-steps',
  props: {
    current: {
      type: Number
    },
    source: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    status: {
      validator: function validator(value) {
        return ['wait', 'process', 'finish', 'error'].includes(value);
      },
      default: 'process'
    }
  },
  data: function data() {
    return {
      steps: [],
      stepOffset: 0
    };
  },
  methods: {
    updateChildProps: function updateChildProps(isInit) {
      var _this = this;

      var total = this.steps.length;
      this.steps.forEach(function (child, index) {
        child.stepNumber = index + 1;

        if (_this.direction === 'horizontal') {
          child.total = total;
        } // 如果已存在status,且在初始化时,则略过
        // todo 如果当前是error,在current改变时需要处理


        if (!(isInit && child.currentStatus)) {
          if (index == _this.current - 1) {
            if (_this.status != 'error') {
              child.currentStatus = 'process';
            } else {
              child.currentStatus = 'error';
            }
          } else if (index < _this.current) {
            child.currentStatus = 'finish';
          } else {
            child.currentStatus = 'wait';
          }
        }

        if (index + 1 === total) {
          child.currentStatus += ' nut-step-last';
        }
      });
    },
    init: function init() {
      if (this.$slots.default) {
        this.steps = this.$slots.default.filter(function (vnode) {
          return !!vnode.componentInstance;
        }).map(function (node) {
          return node.componentInstance;
        });
        this.updateChildProps(true);
      }
    }
  },
  mounted: function mounted() {
    this.init();
  },
  watch: {
    current: function current() {
      this.updateChildProps();
    },
    source: function source() {
      var _this2 = this;

      this.$nextTick(function () {
        _this2.init();
      });
    }
  }
};