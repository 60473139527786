import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var VERTICAL = 'vertical';
var HORIZONTAL = 'horizontal';
export default {
  name: 'nut-swiper',
  props: {
    direction: {
      type: String,
      default: HORIZONTAL,
      validator: function validator(value) {
        return [VERTICAL, HORIZONTAL].indexOf(value) > -1;
      }
    },
    paginationVisible: {
      type: Boolean,
      default: false
    },
    paginationClickable: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    speed: {
      type: Number,
      default: 500
    },
    canDragging: {
      type: Boolean,
      default: true
    },
    autoPlay: {
      type: Number,
      default: 0
    },
    initPage: {
      type: Number,
      default: 1
    },
    lazyLoad: {
      type: Boolean,
      default: false
    },
    lazyLoadUrl: {
      type: String,
      default: ''
    },
    swiperData: {
      type: [Array],
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    swiperData: function swiperData(newValue, oldValue) {
      this.updateEvent();
    }
  },
  data: function data() {
    return {
      dragging: false,
      currentPage: this.initPage,
      lastPage: 1,
      translateX: 0,
      translateY: 0,
      startTranslate: 0,
      slideEls: [],
      translateOffset: 0,
      //当前偏移初始位置距离
      transitionDuration: 0,
      //切换动画时间
      startPos: null,
      delta: 0,
      //拖动距离
      startTime: null,
      isLoop: this.loop,
      timer: null,
      //自动播放定时器
      domTimer: null,
      //渲染延迟查找
      stopAutoPlay: false,
      //停止自动播放
      swiperWrap: null,
      oneSlideTranslate: 0 //一个slide的大小

    };
  },
  methods: {
    //下一张
    next: function next() {
      var page = this.currentPage;

      if (page < this.slideEls.length || this.isLoop) {
        this.setPage(page + 1, true, 'NEXT');
      } else {
        this._revert();
      }
    },
    //上一张
    prev: function prev() {
      var page = this.currentPage;

      if (page > 1 || this.isLoop) {
        this.setPage(page - 1, true, 'PREV');
      } else {
        this._revert();
      }
    },
    setPage: function setPage(page, isHasAnimation, type) {
      if (page === 0) {
        this.currentPage = this.slideEls.length;
      } else if (page === this.slideEls.length + 1) {
        this.currentPage = 1;
      } else {
        this.currentPage = page;
      }

      this._setTranslate(this._getTranslateOfPage(page));

      if (!isHasAnimation) {
        this._slideClassHandle();
      } else {
        this._onTransitionStart(type);
      }
    },
    isHorizontal: function isHorizontal() {
      return this.direction === HORIZONTAL;
    },
    isVertical: function isVertical() {
      return this.direction === VERTICAL;
    },
    renderLazyDom: function renderLazyDom(slideEls) {
      var _this2 = this;

      slideEls.forEach(function (item, index) {
        var src = item.getAttribute('data-src');

        if (item.className != 'nut-swiper-slide nut-swiper-slide-selected') {
          var dom = document.createElement('div'); //jd 图片占位图)

          if (_this2.lazyLoadUrl) {
            dom.style.backgroundImage = "url('".concat(_this2.lazyLoadUrl, "')");
            dom.className = 'nut-lazy img';
          } else {
            dom.className = 'nut-lazy preloader';
          }

          item.appendChild(dom);
        }
      });
      return true;
    },
    updateEvent: function updateEvent(pageSize) {
      var _this3 = this;

      this.$nextTick(function () {
        _this3.domTimer = setTimeout(function () {
          if (pageSize != undefined) _this3.currentPage = pageSize;
          _this3.swiperWrap = _this3.$el.querySelector('.nut-swiper-wrap');
          _this3.slideEls = _toConsumableArray(_this3.swiperWrap.children);
          if (_this3.slideEls.length === 0) return;

          _this3._getSlideDistance(_this3.slideEls[0]);

          if (_this3.autoPlay != 0) {
            _this3.isLoop = true;

            _this3._createAutoPlay();
          }

          _this3.isLoop && _this3._createLoop();

          _this3.setPage(_this3.currentPage, false);

          _this3.lazyLoad && _this3.renderLazyDom(_this3.slideEls) && _this3._imgLazyLoad();
        }, 0);
      });
    },
    _slideClassHandle: function _slideClassHandle() {
      var selectedSlide = this.$el.querySelector('.nut-swiper-slide-selected');
      selectedSlide && selectedSlide.classList.remove('nut-swiper-slide-selected');
      this.slideEls[this.currentPage - 1].classList.add('nut-swiper-slide-selected');
      this.lastPage = this.currentPage;
    },
    _getSlideDistance: function _getSlideDistance(el) {
      var styleArr = getComputedStyle(el);
      var marginTop = styleArr['marginTop'].replace('px', '') - 0;
      var marginBottom = styleArr['marginBottom'].replace('px', '') - 0;
      var marginRight = styleArr['marginRight'].replace('px', '') - 0;
      var marginLeft = styleArr['marginLeft'].replace('px', '') - 0;

      if (this.isHorizontal()) {
        this.oneSlideTranslate = marginRight + marginLeft + el['offsetWidth'];
      } else {
        this.oneSlideTranslate = marginTop + marginBottom + el['offsetHeight'];
      }
    },
    _onTouchStart: function _onTouchStart(e) {
      this.swiperWrap.addEventListener('touchmove', this._onTouchMove, false);
      this.swiperWrap.addEventListener('touchend', this._onTouchEnd, false);
      this.swiperWrap.addEventListener('mousemove', this._onTouchMove, false);
      this.swiperWrap.addEventListener('mouseup', this._onTouchEnd, false);
      this.startPos = this._getTouchPos(e);
      this.delta = 0;
      this.startTranslate = this._getTranslateOfPage(this.currentPage);

      if (this.isLoop) {
        this._setTranslate(this.startTranslate);
      }

      this.startTime = new Date().getTime();
      this.dragging = true;
      this.transitionDuration = 0;
      this.stopAutoPlay = true;
    },
    _onTouchMove: function _onTouchMove(e) {
      if (!this.dragging) return;

      if (this.isHorizontal()) {
        this.delta = this._getTouchPos(e).x - this.startPos.x;
      } else {
        this.delta = this._getTouchPos(e).y - this.startPos.y;
      } //let  isQuickAction = (new Date().getTime() - this.startTime) < 200;


      if (this.canDragging && this._computePreventDefault(e)) {
        e.preventDefault();

        this._setTranslate(this.startTranslate + this.delta);

        this.$emit('slideMove', this._getTranslate(), this.$el);
        this.$emit('slide-move', this._getTranslate(), this.$el);
      }
    },
    _onTouchEnd: function _onTouchEnd(e) {
      if (!this.dragging) return;
      this.dragging = false;
      this.transitionDuration = this.speed;
      var isQuickAction = new Date().getTime() - this.startTime < 1000;

      if (this.delta < -this.oneSlideTranslate / 2 || isQuickAction && this.delta < -15) {
        this.next();
      } else if (this.delta > this.oneSlideTranslate / 2 || isQuickAction && this.delta > 15) {
        this.prev();
      } else {
        this._revert();
      }

      this.swiperWrap.removeEventListener('touchmove', this._onTouchMove, false);
      this.swiperWrap.removeEventListener('touchend', this._onTouchEnd, false);
      this.swiperWrap.removeEventListener('mousemove', this._onTouchMove, false);
      this.swiperWrap.removeEventListener('mouseup', this._onTouchEnd, false);
    },
    _revert: function _revert() {
      this.setPage(this.currentPage, true);
    },
    _computePreventDefault: function _computePreventDefault(e) {
      var pos = this._getTouchPos(e);

      var xDis = Math.abs(this.startPos.x - pos.x);
      var yDis = Math.abs(this.startPos.y - pos.y);

      if (xDis <= 5 && xDis >= 0) {
        return false;
      } else if (yDis > 5 && yDis / xDis > 0.5) {
        return false;
      } else {
        return true;
      }
    },
    _getTouchPos: function _getTouchPos(e) {
      var x = e.changedTouches ? e.changedTouches[0]['pageX'] : e['pageX'];
      var y = e.changedTouches ? e.changedTouches[0]['pageY'] : e['pageY'];
      return {
        x: x,
        y: y
      };
    },
    _onTransitionStart: function _onTransitionStart(type) {
      this.transitionDuration = this.speed;
      this.lazyLoad && this._imgLazyLoad(1);

      if (this._isPageChanged()) {
        this.$emit('slideChangeStart', this.currentPage, this.$el, type);
        this.$emit('slide-change-start', this.currentPage, this.$el, type);
      } else {
        this.$emit('slideRevertStart', this.currentPage, this.$el, type);
        this.$emit('slide-revert-start', this.currentPage, this.$el, type);
      }
    },
    _onTransitionEnd: function _onTransitionEnd() {
      if (this._isPageChanged()) {
        this.$emit('slideChangeEnd', this.currentPage, this.$el);
        this.$emit('slide-change-end', this.currentPage, this.$el);
      } else {
        this.$emit('slideRevertEnd', this.currentPage, this.$el);
        this.$emit('slide-revert-end', this.currentPage, this.$el);
      }

      this.transitionDuration = 0;
      this.delta = 0;
      this.lastPage = this.currentPage;

      this._slideClassHandle();

      if (this.isLoop) {
        this._setTranslate(this._getTranslateOfPage(this.currentPage));
      }

      this.stopAutoPlay = false;
    },
    _isPageChanged: function _isPageChanged() {
      return this.lastPage !== this.currentPage;
    },
    _setTranslate: function _setTranslate(value) {
      var translateName = this.isHorizontal() ? 'translateX' : 'translateY';
      this[translateName] = value;
    },
    _getTranslate: function _getTranslate() {
      var translateName = this.isHorizontal() ? 'translateX' : 'translateY';
      return this[translateName];
    },
    _getTranslateOfPage: function _getTranslateOfPage(page) {
      if (page === 0) return 0;
      var propName = this.isHorizontal() ? 'offsetWidth' : 'offsetHeight';

      var _this = this;

      return -[].reduce.call(this.slideEls, function (total, el, i) {
        return i > page - 2 ? total : total + _this.oneSlideTranslate;
      }, 0) + this.translateOffset;
    },
    _createLoop: function _createLoop() {
      var propName = this.isHorizontal() ? 'offsetWidth' : 'offsetHeight';
      var swiperWrapEl = this.$el.querySelector('.nut-swiper-wrap');
      var duplicateFirstChild = swiperWrapEl.firstElementChild.cloneNode(true);
      var duplicateLastChild = swiperWrapEl.lastElementChild.cloneNode(true);
      swiperWrapEl.insertBefore(duplicateLastChild, swiperWrapEl.firstElementChild);
      swiperWrapEl.appendChild(duplicateFirstChild);
      this.translateOffset = -this.oneSlideTranslate;
    },
    _createAutoPlay: function _createAutoPlay() {
      var _this4 = this;

      clearInterval(this.timer);
      this.timer = setInterval(function () {
        if (!_this4.stopAutoPlay) {
          _this4.next();
        }
      }, this.autoPlay);
    },
    _requestAniFrame: function _requestAniFrame() {
      return window.requestAnimationFrame || window.webkitRequestAnimationFrame || function (callback) {
        window.setTimeout(callback, 1000 / 60);
      };
    },
    _imgLazyLoad: function _imgLazyLoad(type) {
      var _this5 = this;

      var requestAniFrame = this._requestAniFrame();

      var imgLazyLoadEl;
      requestAniFrame(function () {
        imgLazyLoadEl = _this5.swiperWrap.querySelectorAll('.nut-img-lazyload');

        if (type == 1) {
          imgLazyLoadEl = _this5.slideEls[_this5.currentPage - 1].querySelectorAll('.nut-img-lazyload');
        }

        imgLazyLoadEl.forEach(function (item, index) {
          if (!_this5._checkInView(item) && type != 1) return;
          var src = item.getAttribute('data-src');
          var img = new Image();
          img.src = src;

          img.onload = function () {
            var lazyLoadingEle = item.parentElement.querySelector('.nut-lazy');

            if (lazyLoadingEle) {
              item.parentElement.removeChild(lazyLoadingEle);
            }

            item.src = src;
            item.classList.remove('nut-img-lazyload');
          };

          img.onerror = function () {
            item.src = _this5.lazyLoadUrl;
            item.classList.remove('nut-img-lazyload');
          };
        });
      });
    },
    _checkInView: function _checkInView(imgItem) {
      var imgRect = imgItem.getBoundingClientRect();
      var swiperRect = this.$el.getBoundingClientRect();
      var imgTop = imgRect.top;
      var imgLeft = imgRect.left;
      var swiperWidth = this.$el.clientWidth;
      var swiperHeight = this.$el.clientHeight;
      var swiperTop = swiperRect.top;
      var swiperLeft = swiperRect.left;
      var isInView = true;

      if (imgTop > swiperTop + swiperHeight || imgLeft > swiperLeft + swiperWidth) {
        isInView = false;
      }

      return isInView;
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this._onTouchMove = this._onTouchMove.bind(this);
    this._onTouchEnd = this._onTouchEnd.bind(this);
    this.updateEvent();
  },
  destroyed: function destroyed() {
    this.timer && clearInterval(this.timer);
    this.domTimer && clearTimeout(this.domTimer);
  },
  activated: function activated() {
    if (this.keepAlive) {
      this.keepAlive = false;
      this.updateEvent();
    }
  },
  deactivated: function deactivated() {
    this.keepAlive = true;
    this.timer && clearInterval(this.timer);
    this.domTimer && clearTimeout(this.domTimer);
  }
};