//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-luckdraw',
  mounted: function mounted() {
    this.init();
  },
  props: {
    luckWidth: {
      required: true
    },
    luckHeight: {
      required: true
    },
    prizeList: {
      required: true
    },
    turnsNumber: {
      default: 5
    },
    styleOpt: {
      default: function _default() {
        return {
          // 每一块扇形的背景色,默认值,可通过父组件来改变
          prizeBgColors: ['rgb(255, 231, 149)', 'rgb(255, 247, 223)', 'rgb(255, 231, 149)', 'rgb(255, 247, 223)', 'rgb(255, 231, 149)', 'rgb(255, 247, 223)'],
          // 每一块扇形的外边框颜色,默认值,可通过父组件来改变
          borderColor: '#ff9800'
        };
      }
    },
    turnsTime: {
      // 转动需要持续的时间(秒)
      default: 5
    }
  },
  watch: {
    prizeList: function prizeList(newVal, oldVal) {
      this.init();
    }
  },
  data: function data() {
    return {
      winningPrize: 0,
      // 开始转动的角度
      startRotateDegree: 0,
      // 设置指针默认指向的位置,现在是默认指向2个扇形之间的边线上
      rotateAngle: 0,
      //`rotate(30deg)`
      rotateTransition: ''
    };
  },
  methods: {
    // 根据index计算每一格要旋转的角度的样式
    getRotateAngle: function getRotateAngle(index) {
      var angle = 360 / this.prizeList.length * index + 180 / this.prizeList.length;
      return {
        transform: "rotate(".concat(angle, "deg)")
      };
    },
    // 初始化圆形转盘canvas
    init: function init() {
      var data = this.styleOpt;
      var prizeNum = this.prizeList.length;
      var prizeBgColors = data.prizeBgColors,
          borderColor = data.borderColor; // 开始绘画

      var canvas = this.$refs.canvas;
      var ctx = canvas.getContext('2d');
      var canvasW = this.$refs.canvas.width = this.$refs.luckdraw.clientWidth; // 画板的高度

      var canvasH = this.$refs.canvas.height = this.$refs.luckdraw.clientHeight; // 画板的宽度
      // translate方法重新映射画布上的 (0,0) 位置

      ctx.translate(0, canvasH); // rotate方法旋转当前的绘图，因为文字是和当前扇形中心线垂直的

      ctx.rotate(-90 * Math.PI / 180); // 圆环的外圆的半径,可用来调整圆盘大小来适应外部盒子的大小

      var outRadius = canvasW / 2 - 1; // 圆环的内圆的半径

      var innerRadius = 0;
      var baseAngle = Math.PI * 2 / prizeNum; // 每个奖项所占角度数

      ctx.clearRect(0, 0, canvasW, canvasH); //去掉背景默认色

      ctx.strokeStyle = borderColor; // 设置画图线的颜色

      for (var index = 0; index < prizeNum; index++) {
        var angle = index * baseAngle;

        if (this.prizeList[index]['prizeColor']) {
          ctx.fillStyle = this.prizeList[index]['prizeColor']; //设置每个扇形区域的颜色,根据每条数据中单独设置的优先
        } else {
          ctx.fillStyle = prizeBgColors[index]; //设置每个扇形区域的颜色
        }

        ctx.beginPath(); //开始绘制
        // 标准圆弧：arc(x,y,radius,startAngle,endAngle,anticlockwise)

        ctx.arc(canvasW * 0.5, canvasH * 0.5, outRadius, angle, angle + baseAngle, false);
        ctx.arc(canvasW * 0.5, canvasH * 0.5, innerRadius, angle + baseAngle, angle, true);
        ctx.stroke();
        ctx.fill();
        ctx.save();
      }
    },
    // 转动起来
    rotate: function rotate(index) {
      var _this = this;

      var turnsTime = this.turnsTime;
      var rotateAngle = this.startRotateDegree + this.turnsNumber * 360 + 360 - (180 / this.prizeList.length + 360 / this.prizeList.length * index) - this.startRotateDegree % 360;
      this.startRotateDegree = rotateAngle;
      this.rotateAngle = "rotate(".concat(rotateAngle, "deg)");
      this.rotateTransition = "transform ".concat(turnsTime, "s cubic-bezier(0.250, 0.460, 0.455, 0.995)");
      setTimeout(function () {
        _this.$emit('end-turns');
      }, turnsTime * 1000 + 500);
    }
  }
};