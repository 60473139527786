import _typeof from "@babel/runtime/helpers/typeof";
import _extends from "@babel/runtime/helpers/extends";
import Vue from 'vue';
import znCH from './lang/zn-CH';
var defaultMessages = {};
var merged = false;

var vueI18nHandler = function vueI18nHandler() {
  var vuei18n = Object.getPrototypeOf(this || Vue).$t;

  if (typeof vuei18n === 'function' && !!Vue.locale) {
    if (!merged) {
      merged = true;
      Vue.locale(Vue.config.lang, _extends(Vue.config.lang === 'zn-CH' ? znCH : {}, Vue.locale(Vue.config.lang) || {}, defaultMessages));
    }

    return vuei18n.apply(this, arguments);
  }
};

function i18n(keysStr, params) {
  var value = vueI18nHandler.apply(this, arguments);
  if (value !== null && value !== undefined) return value;

  var message = _extends({}, Vue.config.lang === 'zn-CH' ? znCH : {}, defaultMessages);

  var keys = keysStr && keysStr.split('.') || [];
  var result = null;

  if (keys.length) {
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];

      if (key && message[key]) {
        message = message[key];
        result = message;
      } else {
        result = null;
        break;
      }
    }
  }

  return simpleTemplate(result, params);
}

function locale(lang, messages) {
  if (_typeof(lang) === 'object') {
    messages = lang;
    lang = Vue.config.lang;
  }

  Vue.config.lang = lang;

  _extends(defaultMessages, messages);
}

function getArgType(arg) {
  return Object.prototype.toString.call(arg).toLowerCase().match(/\s(\w+)/)[1];
}

function simpleTemplate(templ, conf) {
  var pars = templ && templ.match(/{.+?}/g);

  if (pars && conf) {
    pars = pars.map(function (p) {
      return p.replace(/\{\s*(\w+|\d+).*?\}/, '$1');
    });
    pars.forEach(function (c, i) {
      var reg = new RegExp('{\\s*' + c + '\\s*(?:=\\s*(\\S*?))?\\s*?}', 'g');
      templ = templ.replace(reg, function (a, b) {
        return getArgType(conf[c]) == 'function' ? conf[c]() : conf[c] !== undefined ? conf[c] : b ? b : a;
      });
    });
  }

  return templ;
}

export { i18n, locale };