import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Vue from 'vue';
import settings from './toast.vue';
var ToastConstructor = Vue.extend(settings);
var instance;
var instanceArr = [];
var defaultOptionsMap = {};
var defaultOptions = {
  msg: '',
  visible: false,
  duration: 2000,
  //显示时间(毫秒)
  timer: null,
  center: true,
  type: '',
  customClass: '',
  bottom: 30,
  size: 'base',
  icon: null,
  textAlignCenter: true,
  loadingRotate: true,
  bgColor: 'rgba(36, 36, 36, 0.8)',
  onClose: null,
  textTimer: null,
  cover: false,
  //透明遮罩层
  coverColor: 'rgba(0, 0, 0, 0)',
  timeStamp: null,
  closeOnClickOverlay: false
};

var currentOptions = _objectSpread({}, defaultOptions);

function _showToast() {
  instance.vm = instance.$mount();
  document.body.appendChild(instance.$el);
  Vue.nextTick(function () {
    instance.visible = true;
  });
}

function _getInstance(obj) {
  var opt = _objectSpread(_objectSpread(_objectSpread({
    id: new Date().getTime()
  }, currentOptions), defaultOptionsMap[obj.type]), obj); //有相同id者共用一个实例，否则新增实例


  if (opt['id'] && instanceArr[opt['id']]) {
    instance = instanceArr[opt['id']];
    instance.hide(true);
    instance = _extends(instance, opt);
  } else {
    instance = new ToastConstructor({
      data: _extends(opt, obj)
    });
    opt['id'] && (instanceArr[opt['id']] = instance);
  }

  _showToast();

  return instance;
}

function errorMsg(msg) {
  if (!msg) {
    console.warn('[NutUI Toast]: msg不能为空');
    return;
  }
}

var Toast = {
  text: function text(msg) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    errorMsg(msg);
    return _getInstance(_objectSpread(_objectSpread({}, obj), {}, {
      msg: msg,
      type: 'text'
    }));
  },
  success: function success(msg) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    errorMsg(msg);
    return _getInstance(_objectSpread(_objectSpread({}, obj), {}, {
      msg: msg,
      type: 'success'
    }));
  },
  fail: function fail(msg) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    errorMsg(msg);
    return _getInstance(_objectSpread(_objectSpread({}, obj), {}, {
      msg: msg,
      type: 'fail'
    }));
  },
  warn: function warn(msg) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    errorMsg(msg);
    return _getInstance(_objectSpread(_objectSpread({}, obj), {}, {
      msg: msg,
      type: 'warn'
    }));
  },
  loading: function loading(msg) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    obj = _objectSpread(_objectSpread({}, obj), {}, {
      id: obj.id || 'loading',
      msg: msg,
      type: 'loading'
    });
    obj.cover = typeof obj.cover !== 'undefined' ? obj.cover : true; //loading类型默认打开遮罩层

    obj.duration = obj.duration || 0; //loading类型默认不自动关闭

    return _getInstance(obj);
  },
  setDefaultOptions: function setDefaultOptions(type, options) {
    if (typeof type === 'string') {
      defaultOptionsMap[type] = options;
    } else {
      _extends(currentOptions, type);
    }
  },
  resetDefaultOptions: function resetDefaultOptions(type) {
    if (typeof type === 'string') {
      defaultOptionsMap[type] = null;
    } else {
      currentOptions = _objectSpread({}, defaultOptions);
      defaultOptionsMap = {};
    }
  }
};
export default Toast;