import skeleton from './_skeleton.js';
import './skeleton.scss';
var skeletonCircle = skeleton.skeletonCircle,
    skeletonSquare = skeleton.skeletonSquare,
    skeletonRow = skeleton.skeletonRow,
    skeletonColumn = skeleton.skeletonColumn;

skeleton.install = function (Vue) {
  Vue.component(skeleton.name, skeleton);
};

skeletonCircle.install = function (Vue) {
  Vue.component(skeletonCircle.name, skeletonCircle);
};

skeletonSquare.install = function (Vue) {
  Vue.component(skeletonSquare.name, skeletonSquare);
};

skeletonRow.install = function (Vue) {
  Vue.component(skeletonRow.name, skeletonRow);
};

skeletonColumn.install = function (Vue) {
  Vue.component(skeletonColumn.name, skeletonColumn);
};

export default {
  skeleton: skeleton,
  skeletonCircle: skeletonCircle,
  skeletonSquare: skeletonSquare,
  skeletonRow: skeletonRow,
  skeletonColumn: skeletonColumn
};