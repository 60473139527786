//
//
//
//
//
//
import LuckyCard from './luckycard.js';
export default {
  name: 'nut-luckycard',
  props: {
    content: {
      type: String,
      default: ''
    },
    height: {
      type: [String, Number],
      default: 50
    },
    width: {
      type: [String, Number],
      default: 300
    },
    coverColor: {
      type: String,
      default: '#C5C5C5'
    },
    coverImg: {
      type: String,
      default: ''
    },
    fontSize: {
      type: [String, Number],
      default: 20
    },
    backgroundColor: {
      type: String,
      default: '#FFFFFF'
    },
    ratio: {
      type: [String, Number],
      default: 0.5
    }
  },
  data: function data() {
    return {};
  },
  methods: {},
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      var _vm = _this;
      LuckyCard({
        scratchDiv: _this.$el,
        coverColor: _this.coverColor,
        coverImg: _this.coverImg,
        ratio: Number(_this.ratio),
        callback: function callback() {
          //console.log(this);
          //this.clearCover();
          _vm.$emit('open', this);
        }
      });
    });
  }
};