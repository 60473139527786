import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import requestAniFrame from '../../utils/raf.js';
export default {
  name: 'nut-range-bar',
  props: {
    direction: {
      type: String,
      default: 'left'
    },
    range: {
      type: Array,
      validator: function validator(value) {
        return value.length === 2 && value[1] > value[0];
      },
      default: function _default() {
        return [0, 10];
      }
    },
    values: {
      type: Array,
      validator: function validator(value) {
        return value.length === 2 && value[1] >= value[0];
      },
      default: function _default() {
        return [0, 0];
      }
    },
    initLeft: {
      type: Number,
      default: 0
    },
    showLabelAlways: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    current: {
      type: Number,
      default: 0
    },
    stage: {
      type: Number,
      default: 0
    },
    ani: Boolean,
    mainColor: String,
    subColor: String
  },
  data: function data() {
    return {
      box: null,
      posi: 0,
      scheduledAnimationFrame: false
    };
  },
  watch: {
    initLeft: function initLeft(val) {
      this.posi = this.initLeft;
    }
  },
  computed: {
    total: function total() {
      return this.range[1] - this.range[0];
    }
  },
  methods: {
    onTouchStart: function onTouchStart(event) {
      if (event.cancelable) {
        event.preventDefault();
      }

      this.$emit('update:ani', true);
    },
    onTouchMove: function onTouchMove(event) {
      var _this = this;

      if (event.cancelable) {
        event.preventDefault();
      }

      if (this.scheduledAnimationFrame) return;
      this.scheduledAnimationFrame = true;
      requestAniFrame(function () {
        _this.scheduledAnimationFrame = false;
        var evt = event.touches[0];
        var pageScrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;
        _this.boxLeft = _this.box.getBoundingClientRect().left;
        var posi = evt.pageX - _this.boxLeft - pageScrollLeft;

        _this.setPosi(posi, false);
      });
    },
    setPosi: function setPosi(posi, isEnd) {
      if (posi < 0) {
        posi = 0;
      }

      if (posi > this.box.clientWidth) {
        posi = this.box.clientWidth;
      }

      var _this$values = _slicedToArray(this.values, 2),
          prevLeft = _this$values[0],
          prevRight = _this$values[1];

      var _this$range = _slicedToArray(this.range, 2),
          rangeLeft = _this$range[0],
          rangeRight = _this$range[1];

      if (this.direction === 'left') {
        var maxRight = prevRight - rangeLeft - 1;

        if (posi / this.box.clientWidth >= maxRight / this.total) {
          this.posi = maxRight * (this.box.clientWidth / this.total);
        } else {
          this.posi = posi;
        }
      }

      if (this.direction === 'right') {
        var minLeft = prevLeft - rangeLeft + 1;

        if (posi / this.box.clientWidth <= minLeft / this.total) {
          this.posi = minLeft * (this.box.clientWidth / this.total);
        } else {
          this.posi = posi;
        }
      }

      this.$emit('getPos', this.posi, isEnd);
    },
    onTouchEnd: function onTouchEnd(event) {
      var _this2 = this;

      event.preventDefault();
      var evt = event.changedTouches[0];
      var pageScrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;
      this.boxLeft = this.box.getBoundingClientRect().left;
      var posi = evt.pageX - this.boxLeft - pageScrollLeft;
      setTimeout(function () {
        _this2.setPosi(posi, true);

        _this2.$emit('update:ani', false);
      }, 50);
    },
    onClick: function onClick(event) {
      event.preventDefault();
      var pageScrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;
      this.boxLeft = this.box.getBoundingClientRect().left;
      var posi = event.pageX - this.boxLeft - pageScrollLeft;
      this.posi = posi;
      this.$emit('getPos', posi);
    }
  },
  mounted: function mounted() {
    this.box = this.$el.parentNode;
  }
};