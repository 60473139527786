//
//
//
//
//
//
//
export default {
  name: 'nut-sidenavbaritem',
  props: {
    title: {
      type: String,
      default: ''
    },
    ikey: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClick: function handleClick() {
      this.$emit('click');
    }
  }
};