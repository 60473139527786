import _defineProperty from "@babel/runtime/helpers/defineProperty";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-fixednav',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    navList: {
      default: function _default() {
        return [];
      },
      type: Array
    },
    activeText: {
      default: '收起导航',
      type: String
    },
    unActiveText: {
      default: '快速导航',
      type: String
    },
    type: {
      default: 'right',
      type: String
    }
  },
  computed: {
    styled: function styled() {
      return _defineProperty({
        active: this.defaultActive
      }, this.defaultType, true);
    }
  },
  watch: {
    defaultActive: function defaultActive(newV, oldV) {
      this.$emit('update:active', newV);
    }
  },
  data: function data() {
    return {
      defaultActive: false,
      defaultType: ''
    };
  },
  mounted: function mounted() {
    this.defaultActive = this.active;
    this.defaultType = this.type;
  },
  methods: {
    selected: function selected(item, $event) {
      this.$emit('selected', {
        item: item,
        $event: $event
      });
    }
  }
};