//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-progress',
  props: {
    percentage: {
      type: [Number, String],
      default: 0,
      required: true
    },
    size: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: 'text'
    },
    strokeWidth: {
      type: [Number, String],
      default: ''
    },
    textInside: {
      type: Boolean,
      default: false
    },
    showText: {
      type: Boolean,
      default: true
    },
    strokeColor: {
      type: String,
      default: ''
    },
    textColor: {
      tyep: String,
      default: ''
    }
  },
  data: function data() {
    return {
      height: this.strokeWidth + 'px'
    };
  },
  computed: {
    bgStyle: function bgStyle() {
      return {
        width: this.percentage + '%',
        backgroundColor: this.strokeColor || ''
      };
    },
    textStyle: function textStyle() {
      return {
        color: this.textColor || ''
      };
    },
    statusIcon: function statusIcon() {
      return this.status === 'success' ? 'nut-icon-success' : this.status === 'wrong' ? 'nut-icon-fail' : '';
    }
  }
};