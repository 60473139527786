//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-infiniteloading',
  props: {
    hasMore: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    threshold: {
      type: Number,
      default: 200
    },
    useWindow: {
      type: Boolean,
      default: true
    },
    useCapture: {
      type: Boolean,
      default: false
    },
    isShowMod: {
      type: Boolean,
      default: false
    },
    unloadMoreTxt: {
      type: String,
      default: '哎呀，这里是底部了啦'
    },
    scrollChange: {
      type: Function
    }
  },
  data: function data() {
    return {
      startX: 0,
      startY: 0,
      diffX: 0,
      diffY: 0,
      beforeScrollTop: 0
    };
  },
  mounted: function mounted() {
    var parentElement = this.getParentElement(this.$el);
    var scrollEl = window;

    if (this.useWindow === false) {
      scrollEl = parentElement;
    }

    this.scrollEl = scrollEl;
    this.scrollListener();
  },
  methods: {
    touchStartHandle: function touchStartHandle(e) {
      try {
        this.startX = Number(e.changedTouches[0].pageX);
        this.startY = Number(e.changedTouches[0].pageY);
      } catch (e) {
        console.log(e.message);
      }
    },
    touchMoveHandle: function touchMoveHandle(e) {
      var endX = Number(e.changedTouches[0].pageX);
      var endY = Number(e.changedTouches[0].pageY);
      this.diffX = endX - this.startX;
      this.diffY = endY - this.startY;
    },
    getParentElement: function getParentElement(el) {
      return el && el.parentNode;
    },
    scrollListener: function scrollListener() {
      this.scrollEl.addEventListener('scroll', this.handleScroll, this.useCapture);
      window.addEventListener('resize', this.handleScroll, this.useCapture);
    },
    requestAniFrame: function requestAniFrame() {
      return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
        window.setTimeout(callback, 1000 / 60);
      };
    },
    handleScroll: function handleScroll() {
      var _this = this;

      this.requestAniFrame()(function () {
        if (!_this.isScrollAtBottom() || !_this.hasMore || _this.isLoading || !_this.isShowMod) {
          return false;
        } else {
          _this.$emit('loadmore');
        }
      });
    },
    calculateTopPosition: function calculateTopPosition(el) {
      if (!el) {
        return 0;
      }

      return el.offsetTop + this.calculateTopPosition(el.offsetParent);
    },
    getWindowScrollTop: function getWindowScrollTop() {
      return window.pageYOffset !== undefined ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    },
    isScrollAtBottom: function isScrollAtBottom() {
      var offsetDistance;
      var resScrollTop = 0;
      var windowScrollTop = this.getWindowScrollTop();

      if (this.useWindow) {
        offsetDistance = this.calculateTopPosition(this.$refs.scroller) + this.$refs.scroller.offsetHeight - windowScrollTop - window.innerHeight;
      } else {
        var _this$scrollEl = this.scrollEl,
            scrollHeight = _this$scrollEl.scrollHeight,
            clientHeight = _this$scrollEl.clientHeight,
            scrollTop = _this$scrollEl.scrollTop;
        offsetDistance = scrollHeight - clientHeight - scrollTop;
        resScrollTop = scrollTop;
      }

      this.$emit('scrollChange', this.useWindow ? windowScrollTop : resScrollTop); // 保证是往下滑动的

      var beforeScrollTop = this.beforeScrollTop;
      this.beforeScrollTop = windowScrollTop;
      return offsetDistance <= this.threshold && windowScrollTop >= this.beforeScrollTop;
    }
  },
  activated: function activated() {
    if (this.keepAlive) {
      this.keepAlive = false;
      this.scrollListener();
    }
  },
  deactivated: function deactivated() {
    this.keepAlive = true;
    this.scrollEl.removeEventListener('scroll', this.handleScroll, this.useCapture);
    window.removeEventListener('resize', this.handleScroll, this.useCapture);
  },
  destroyed: function destroyed() {
    this.scrollEl.removeEventListener('scroll', this.handleScroll, this.useCapture);
    window.removeEventListener('resize', this.handleScroll, this.useCapture);
  }
};