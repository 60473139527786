//
//
//
//
//
//
export default {
  name: 'nut-badge',
  props: {
    value: {
      type: [String, Number]
    },
    max: {
      type: Number,
      default: 10000
    },
    isDot: {
      type: Boolean,
      default: false
    },
    hidden: {
      type: Boolean,
      default: false
    },
    top: {
      type: String,
      default: '0'
    },
    right: {
      type: String,
      default: '0'
    },
    zIndex: {
      type: Number,
      default: 10
    }
  },
  data: function data() {
    return {
      stl: {
        top: this.top,
        right: this.right,
        zIndex: this.zIndex
      }
    };
  },
  computed: {
    content: function content() {
      if (this.isDot) return;
      var value = this.value;
      var max = this.max;

      if (typeof value === 'number' && typeof max === 'number') {
        return max < value ? "".concat(max, "+") : value;
      }

      return value;
    }
  }
};