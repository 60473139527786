//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-navbar',
  props: {
    title: {
      type: String,
      default: 'NavBar'
    },
    leftShow: {
      type: Boolean,
      default: true
    },
    rightShow: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    onClickBack: function onClickBack() {
      // this.$emit('on-click-back');
      this.$router ? this.$router.back() : window.history.back();
    }
  }
};