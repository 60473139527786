import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _components;

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import nuttab from '../tab/tab.vue';
import '../tab/tab.scss';
import nutpop from '../popup/popup.vue';
import '../popup/popup.scss';
export default {
  name: 'nut-tabselect',
  props: {
    mainTitle: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    isLockBgScroll: {
      type: Boolean,
      default: true
    },
    defaultContent: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    tabList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    show: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: Infinity
    },
    isDefaultSelected: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isShow: false,
      level0: 0,
      level1: new Set([0]),
      level2: this.isDefaultSelected ? new Set(['0-0']) : new Set(),
      allChoose: this.getText(0, 0, this.isDefaultSelected ? 0 : null),
      list: [],
      defIndex: 0
    };
  },
  components: (_components = {}, _defineProperty(_components, nuttab.name, nuttab), _defineProperty(_components, nutpop.name, nutpop), _components),
  watch: {
    show: function show(val) {
      this.isShow = val;
    },
    isShow: function isShow(val) {
      if (!val) {
        this.$emit('close');
      }
    },
    tabList: {
      handler: function handler(val) {
        this.list = val;
        this.level0 = 0;
        this.level1 = new Set([0]);
        this.level2 = this.isDefaultSelected ? new Set(['0-0']) : new Set();
        this.allChoose = this.getText(0, 0, this.isDefaultSelected ? 0 : null);
        this.emit();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.list = this.tabList;
    this.allChoose = this.getText(0, 0, this.isDefaultSelected ? 0 : null);
    this.emit();
  },
  methods: {
    emit: function emit() {
      this.$emit('choose', this.list && this.list[this.level0] && this.list[this.level0].tabTitle || '', this.allChoose && _toConsumableArray(this.allChoose) || []);
    },
    getText: function getText(idx, index, sIndex) {
      if (sIndex === null) {
        return null;
      }

      var tab = this.list && this.list[idx] && this.list[idx].children[index] || {};
      var subTit = tab.tabTitle;
      var content = tab.content && tab.content[sIndex] || this.defaultContent[sIndex];
      return new Set([{
        subTit: subTit,
        content: content
      }]);
    },
    tabSwitchOuter: function tabSwitchOuter(index, event) {
      this.defIndex = 0;
      this.level0 = index;
      this.level1 = new Set([0]);
      this.level2 = this.isDefaultSelected ? new Set(['0-0']) : new Set();
      this.allChoose = this.getText(index, 0, this.isDefaultSelected ? 0 : null);
      this.emit();
    },
    tabSwitchInner: function tabSwitchInner(index, event) {
      this.defIndex = index;

      if (!this.multiple) {
        this.level1 = new Set([index]);
      } else {
        this.level1.add(index);
      }
    },
    unChoose: function unChoose(index, sIndex) {
      this.level2.delete(index + '-' + sIndex);
      this.level2 = new Set(this.level2);
    },
    choose: function choose(idx, index, sIndex) {
      var _this = this;

      if (this.multiple && this.isActive(idx, index, sIndex)) {
        this.unChoose(index, sIndex);
        this.getText(idx, index, sIndex).forEach(function (o) {
          var _iterator = _createForOfIteratorHelper(_this.allChoose.values()),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var indexdel = _step.value;

              if (JSON.stringify(o) === JSON.stringify(indexdel)) {
                _this.allChoose.delete(indexdel);
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        });
        this.emit();
        return;
      }

      if (!this.multiple) {
        this.level2 = new Set([index + '-' + sIndex]);
        this.allChoose = this.getText(idx, index, sIndex);
      } else {
        if (this.max !== Infinity && this.max === this.level2.size) {
          return;
        }

        this.level2 = new Set(_toConsumableArray(this.level2.add(index + '-' + sIndex)));

        if (this.allChoose) {
          var _this$allChoose;

          (_this$allChoose = this.allChoose).add.apply(_this$allChoose, _toConsumableArray(this.getText(idx, index, sIndex)));
        } else {
          this.allChoose = this.getText(idx, index, sIndex);
        }
      }

      this.emit();
    },
    clickHandler: function clickHandler(event) {
      this.$emit('onOkBtn', event);
      this.isShow = false;
    },
    isActive: function isActive(idx, index, sIndex) {
      if (idx === this.level0 && this.level1.has(index) && this.level2.has(index + '-' + sIndex)) {
        return true;
      }

      return false;
    }
  }
};