//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-noticebar',
  props: {
    text: {
      type: String,
      default: ''
    },
    closeMode: {
      type: Boolean,
      default: false
    },
    wrapable: {
      type: Boolean,
      default: false
    },
    leftIcon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#F9911B'
    },
    background: {
      type: String,
      default: 'rgba(254,250,216,1)'
    },
    delay: {
      type: [String, Number],
      default: 1
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    speed: {
      type: Number,
      default: 50
    }
  },
  data: function data() {
    return {
      wrapWidth: 0,
      firstRound: true,
      duration: 0,
      offsetWidth: 0,
      showNoticeBar: true,
      animationClass: ''
    };
  },
  computed: {
    iconShow: function iconShow() {
      if (this.leftIcon == 'close') {
        return false;
      } else {
        return true;
      }
    },
    barStyle: function barStyle() {
      return {
        color: this.color,
        background: this.background
      };
    },
    contentStyle: function contentStyle() {
      return {
        paddingLeft: this.firstRound ? 0 : this.wrapWidth + 'px',
        animationDelay: (this.firstRound ? this.delay : 0) + 's',
        animationDuration: this.duration + 's'
      };
    },
    iconBg: function iconBg() {
      var iconBg = null;

      if (this.leftIcon) {
        iconBg = this.leftIcon;
      }

      return iconBg;
    }
  },
  watch: {
    text: {
      handler: function handler(val, newVal) {
        var _this = this;

        // console.log(val, newVal, this.showNoticeBar == false, 'text');
        if (this.showNoticeBar == false) {
          return;
        }

        this.$nextTick(function () {
          var _this$$refs = _this.$refs,
              wrap = _this$$refs.wrap,
              content = _this$$refs.content;

          if (!wrap || !content) {
            return;
          }

          var wrapWidth = wrap.getBoundingClientRect().width;
          var offsetWidth = content.getBoundingClientRect().width; // console.log(wrapWidth, offsetWidth, 'text222');

          if (_this.scrollable && offsetWidth > wrapWidth) {
            _this.wrapWidth = wrapWidth;
            _this.offsetWidth = offsetWidth;
            _this.duration = offsetWidth / _this.speed;
            _this.animationClass = 'play';
          } else {
            _this.animationClass = '';
          }
        });
      },
      immediate: true
    }
  },
  methods: {
    handleClick: function handleClick(event) {
      this.$emit('click', event);
    },
    onClickIcon: function onClickIcon(event) {
      this.showNoticeBar = !this.closeMode;
      this.$emit('close', event);
    },
    onAnimationEnd: function onAnimationEnd() {
      var _this2 = this;

      this.firstRound = false;
      this.$nextTick(function () {
        _this2.duration = (_this2.offsetWidth + _this2.wrapWidth) / _this2.speed;
        _this2.animationClass = 'play-infinite';
      });
    }
  }
};