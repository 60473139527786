import _extends from "@babel/runtime/helpers/extends";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-circleprogress',
  props: {
    progress: {
      type: [Number, String],
      required: true
    },
    strokeInnerWidth: {
      type: [Number, String],
      default: 10
    },
    isAuto: {
      tyep: Boolean,
      default: false
    },
    progressOption: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    arcLength: function arcLength() {
      var circleLength = Math.floor(2 * Math.PI * this.option.radius);
      var progressLength = this.progress / 100 * circleLength; // console.log(this.progress,progressLength)

      return "".concat(progressLength, ",").concat(circleLength);
    },
    // :stroke-dashoffset="dashOffset"
    // dashOffset() {
    //   // 偏移初始值为dashArray长度，随着percent增大逐渐减小
    //   return (1 - this.progress) *Math.floor(2 * Math.PI * this.option.radius);
    // },
    option: function option() {
      // 所有进度条的可配置项
      var baseOption = {
        radius: 50,
        strokeOutWidth: 10,
        backColor: '#d9d9d9',
        progressColor: 'red'
      };

      _extends(baseOption, this.progressOption); // 圆心位置自动生成


      baseOption.cy = baseOption.cx = baseOption.radius + baseOption.strokeOutWidth;
      baseOption.size = (baseOption.radius + baseOption.strokeOutWidth) * 2;
      return baseOption;
    }
  }
};