import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { overlayProps, getProps } from '../popup/index';
import Popup from './../popup/popup.vue';
export default {
  name: 'nut-notify',
  props: _objectSpread(_objectSpread({}, overlayProps), {}, {
    color: {
      type: String,
      default: ''
    },
    msg: {
      type: [Number, String],
      default: ''
    },
    duration: {
      type: [Number, String],
      default: 3000
    },
    className: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'danger'
    },
    showPopup: {
      type: Boolean,
      default: false
    }
  }),
  watch: {
    showPopup: {
      handler: function handler(val) {
        if (val) {
          this.curVisible = val;
          this.show();
        }
      } // immediate: true

    }
  },
  data: function data() {
    return {
      timer: null,
      curVisible: false
    };
  },
  components: {
    'nut-popup': Popup
  },
  methods: {
    handleClick: function handleClick() {
      typeof this.onClick === 'function' && this.onClick();
    },
    handleOpened: function handleOpened() {
      typeof this.onOpened === 'function' && this.onOpened();
    },
    handleClosed: function handleClosed() {
      typeof this.onClosed === 'function' && this.onClosed();
    },
    show: function show(force) {
      var _this = this;

      this.clearTimer();
      clearTimeout(this.textTimer);

      if (this.duration) {
        this.timer = setTimeout(function () {
          _this.hide(force);
        }, this.duration);
      }
    },
    hide: function hide(force) {
      var _this2 = this;

      this.clearTimer();
      this.curVisible = false;

      if (force) {
        clearTimeout(this.textTimer);
      } else {
        this.textTimer = setTimeout(function () {
          clearTimeout(_this2.textTimer);
        }, 300);
      }
    },
    clearTimer: function clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    }
  },
  destroyed: function destroyed() {
    this.textTimer = null;
    this.timer = null;
  }
};