import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Movebar from './movebar.vue';

function toRGBA(c, opacity) {
  // 16进制、rgb颜色转rgba
  var reg = /(rgb)(\(\d+,\s*\d+,\s*\d+)(\))/;
  if (reg.test(c)) return c.replace(reg, '$1' + (opacity ? 'a' : '') + '$2' + (opacity ? ',' + opacity : '') + '$3');

  if (/^#[0-9a-f]+/.test(c)) {
    if (c.length === 4) {
      c = c.replace(/^#(\d)(\d)(\d)/, '#$1$1$2$2$3$3');
    }

    if (c.length === 7) {
      return toRGBA('rgb(' + parseInt(c.slice(1, 3), 16) + ', ' + parseInt(c.slice(3, 5), 16) + ', ' + parseInt(c.slice(5, 7), 16) + ')', opacity);
    }
  }

  return toRGBA('#ff0000', opacity);
}

export { toRGBA };
export default {
  name: 'nut-range',
  components: _defineProperty({}, Movebar.name, Movebar),
  props: {
    rangeValues: {
      type: Array,
      validator: function validator(value) {
        return value.length === 2 && value[1] >= value[0];
      },
      default: function _default() {
        return [0, 0];
      }
    },
    color: String,
    showLabel: {
      type: Boolean,
      default: false
    },
    showLabelAlways: {
      type: Boolean,
      default: false
    },
    showRangeTxt: {
      type: Boolean,
      default: false
    },
    range: {
      type: Array,
      validator: function validator(value) {
        return value.length === 2 && value[1] > value[0];
      },
      default: function _default() {
        return [0, 10];
      }
    },
    stage: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      box: null,
      boxLeft: 0,
      initLeft1: 0,
      initLeft2: 0,
      currentLeft: 0,
      currentRight: 0,
      barleft1: 0,
      barleft2: 0,
      level: null,
      ani: false,
      prevValues: []
    };
  },
  watch: {
    range: function range() {
      this.init();
    },
    rangeValues: function rangeValues() {
      this.init();
    },
    ani: function ani(flag) {
      if (flag) {
        this.prevValues = this.rangeValues;
      }
    }
  },
  computed: {
    total: function total() {
      return this.range[1] - this.range[0];
    },
    cell: function cell() {
      return this.total / this.level;
    },
    mainColor: function mainColor() {
      return this.color ? toRGBA(this.color) : '';
    },
    boxColor: function boxColor() {
      return this.color ? toRGBA(this.color, 0.3) : '';
    },
    subColor: function subColor() {
      return this.color ? toRGBA(this.color, 0.5) : '';
    }
  },
  methods: {
    init: function init() {
      this.box = this.$el.querySelector('.nut-range-box');
      this.propInit();
    },
    updateRangeValues: function updateRangeValues() {
      var rangeValues = [this.currentLeft, this.currentRight];
      this.$emit('update:rangeValues', rangeValues);
    },
    getPosLeft: function getPosLeft(pos, isEnd) {
      this.barleft1 = pos;
      var currentLeft = this.setCurrent(pos);

      var _this$prevValues = _slicedToArray(this.prevValues, 2),
          prevLeft = _this$prevValues[0],
          prevRight = _this$prevValues[1];

      if (isEnd) {
        if (this.stage) {
          var stageNum = 0;

          if (currentLeft > prevLeft) {
            stageNum = Math.ceil(currentLeft / this.stage);
          } else {
            stageNum = Math.floor(currentLeft / this.stage);
          }

          this.currentLeft = stageNum * this.stage;
        } else {
          this.currentLeft = currentLeft;
        }
      } else {
        this.currentLeft = currentLeft;
      }

      if (isEnd) {
        this.updateRangeValues();
      }
    },
    getPosRight: function getPosRight(pos, isEnd) {
      this.barleft2 = pos;
      var currentRight = this.setCurrent(pos);

      var _this$prevValues2 = _slicedToArray(this.prevValues, 2),
          prevLeft = _this$prevValues2[0],
          prevRight = _this$prevValues2[1];

      if (isEnd) {
        if (this.stage) {
          var stageNum = 0;

          if (currentRight > prevRight) {
            stageNum = Math.ceil(currentRight / this.stage);
          } else {
            stageNum = Math.floor(currentRight / this.stage);
          }

          this.currentRight = stageNum * this.stage;
        } else {
          this.currentRight = currentRight;
        }
      } else {
        this.currentRight = currentRight;
      }

      if (isEnd) {
        this.updateRangeValues();
      }
    },
    setCurrent: function setCurrent(posi) {
      var trans = posi / this.box.clientWidth * this.total;
      var current = trans / this.cell * this.cell + this.range[0];
      return current > this.range[1] - 1 ? this.range[1] : current < this.range[0] + 1 ? this.range[0] : Math.round(current);
    },
    setVal: function setVal(posi) {
      var trans = posi / this.box.clientWidth * this.total;
      this.current = Math.round(trans / this.cell) * this.cell + this.range[0];
      this.$emit('update:value', this.current);
    },
    valToPosi: function valToPosi(current) {
      var pos = (current - this.range[0]) * (this.box.clientWidth / this.total);
      return pos;
    },
    setPosi: function setPosi(posi) {
      if (posi < 0 || posi > this.box.clientWidth) return;
      this.posi = posi;
      this.setVal(posi);
    },
    onClick: function onClick(event) {
      event.preventDefault();
      var pageScrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;
      this.boxLeft = this.box.getBoundingClientRect().left;
      var posi = event.pageX - this.boxLeft - pageScrollLeft;
      this.setVal(posi);
      this.posi = this.valToPosi();
    },
    propInit: function propInit() {
      if (!this.stage || this.stage > this.range[1] - this.range[0]) {
        this.level = this.range[1] - this.range[0];
      } else {
        this.level = this.stage;
      }

      this.currentLeft = Math.max(this.rangeValues[0], this.range[0]);
      this.currentRight = Math.max(this.currentLeft, Math.min(this.rangeValues[1], this.range[1]));
      this.initLeft1 = this.valToPosi(this.currentLeft);
      this.initLeft2 = this.valToPosi(this.currentRight);
      this.barleft1 = this.initLeft1;
      this.barleft2 = this.initLeft2;
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.init();
    });
  }
};