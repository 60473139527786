import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _components;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import nutVertScroll from './vertical-scroll.vue';
import nutHorScroll from './horizontal-scroll.vue';
export default {
  name: 'nut-scroller',
  props: {
    type: {
      type: String,
      default: 'horizontal'
    },
    stretch: {
      type: Number,
      default: 100
    },
    isUnMore: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    threshold: {
      type: Number,
      default: 100
    },
    pulldownTxt: {
      type: String,
      default: '下拉刷新'
    },
    loadMoreTxt: {
      type: String,
      default: '上拉加载'
    },
    unloadMoreTxt: {
      type: String,
      default: '没有更多了'
    },
    propsTime: {
      type: Number,
      default: 0
    },
    scrollTo: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {};
  },
  components: (_components = {}, _defineProperty(_components, nutVertScroll.name, nutVertScroll), _defineProperty(_components, nutHorScroll.name, nutHorScroll), _components),
  methods: {
    loadMore: function loadMore() {
      this.$emit('loadMore');
    },
    jump: function jump() {
      this.$emit('jump');
    },
    pulldown: function pulldown() {
      this.$emit('pulldown');
    },
    scrollToCbk: function scrollToCbk() {
      this.$emit('scrollToCbk');
    },
    scrollChange: function scrollChange(event) {
      this.$emit('scrollChange', event);
    }
  }
};