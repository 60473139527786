import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
//
//
//
//
//
export default {
  name: 'nut-col',
  props: {
    span: {
      type: [String, Number],
      default: '24'
    },
    offset: {
      type: [String, Number],
      default: '0'
    }
  },
  data: function data() {
    return {
      classObject: {}
    };
  },
  watch: {
    span: {
      handler: function handler(val) {
        if (val) {
          this.classObject = _extends(this.classObject, _defineProperty({}, 'nut-col-' + val, this.span == val));
        }
      },
      immediate: true
    },
    offset: {
      handler: function handler(val) {
        if (val) {
          this.classObject = _extends(this.classObject, _defineProperty({}, 'nut-col-offset-' + val, this.offset == val));
        }
      },
      immediate: true
    }
  }
};