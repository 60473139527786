//
//
//
//
//
//
//
//
//
//
//
import NutButton from '../button/button.vue';
import '../button/button.scss';
export default {
  name: 'nut-signature',
  props: {
    customClass: {
      type: String,
      default: ''
    },
    lineWidth: {
      type: Number,
      default: 2
    },
    strokeStyle: {
      type: String,
      default: '#000'
    },
    type: {
      type: String,
      default: 'png'
    },
    unSupportTpl: {
      type: String,
      default: '对不起，当前浏览器不支持Canvas，无法使用本控件！'
    }
  },
  data: function data() {
    return {
      canvasHeight: 0,
      canvasWidth: 0,
      ctx: null,
      isSupportTouch: 'ontouchstart' in window,
      events: 'ontouchstart' in window ? ['touchstart', 'touchmove', 'touchend', 'touchleave'] : ['mousedown', 'mousemove', 'mouseup', 'mouseleave']
    };
  },
  components: {
    'nut-button': NutButton
  },
  computed: {
    isCanvasSupported: function isCanvasSupported() {
      var elem = document.createElement('canvas');
      return !!(elem.getContext && elem.getContext('2d'));
    }
  },
  methods: {
    addEvent: function addEvent() {
      this.startEventHandler = this.startEventHandler.bind(this), this.$refs.canvas.addEventListener(this.events[0], this.startEventHandler, false);
    },
    startEventHandler: function startEventHandler(event) {
      event.preventDefault();
      this.ctx.beginPath();
      this.ctx.lineWidth = this.lineWidth;
      this.ctx.strokeStyle = this.strokeStyle;
      this.moveEventHandler = this.moveEventHandler.bind(this), this.leaveEventHandler = this.leaveEventHandler.bind(this), this.endEventHandler = this.endEventHandler.bind(this);
      this.$refs.canvas.addEventListener(this.events[1], this.moveEventHandler, false);
      this.$refs.canvas.addEventListener(this.events[2], this.endEventHandler, false);
      this.$refs.canvas.addEventListener(this.events[3], this.leaveEventHandler, false);
    },
    moveEventHandler: function moveEventHandler(event) {
      event.preventDefault();
      var evt = this.isSupportTouch ? event.touches[0] : event;
      var coverPos = this.$refs.canvas.getBoundingClientRect();
      var mouseX = evt.clientX - coverPos.left;
      var mouseY = evt.clientY - coverPos.top;
      this.ctx.lineTo(mouseX, mouseY);
      this.ctx.stroke();
    },
    endEventHandler: function endEventHandler(event) {
      event.preventDefault();
      this.$refs.canvas.removeEventListener(this.events[1], this.moveEventHandler, false);
      this.$refs.canvas.removeEventListener(this.events[2], this.endEventHandler, false);
    },
    leaveEventHandler: function leaveEventHandler(event) {
      event.preventDefault();
      this.$refs.canvas.removeEventListener(this.events[1], this.moveEventHandler, false);
      this.$refs.canvas.removeEventListener(this.events[2], this.endEventHandler, false);
    },
    clear: function clear(isUnEmit) {
      this.$refs.canvas.addEventListener(this.events[2], this.endEventHandler, false);
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      this.ctx.closePath();

      if (!isUnEmit) {
        this.$emit('clear');
      }
    },
    confirm: function confirm() {
      this.onSave(this.$refs.canvas);
    },
    onSave: function onSave(canvas) {
      var dataurl;

      switch (this.type) {
        case 'png':
          dataurl = canvas.toDataURL('image/png');
          break;

        case 'jpg':
          dataurl = canvas.toDataURL('image/jpeg', 0.8);
          break;
      }

      this.clear(true);
      this.$emit('confirm', canvas, dataurl);
    }
  },
  mounted: function mounted() {
    if (this.isCanvasSupported) {
      this.ctx = this.$refs.canvas.getContext('2d');
      this.canvasWidth = this.$refs.wrap.offsetWidth;
      this.canvasHeight = this.$refs.wrap.offsetHeight, this.addEvent();
    }
  }
};