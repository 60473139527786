//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { throttle } from '../../utils/throttle';
export default {
  name: 'nut-video',
  props: {
    sources: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    options: {
      type: Object,
      default: function _default() {
        return {
          autoplay: false,
          //是否自动播放
          volume: 0.5,
          poster: '',
          loop: false,
          controls: true,
          muted: false,
          //是否静音
          disabled: false,
          //禁止操作
          playsinline: false,
          //行内展示
          touchPlay: false,
          preload: ''
        };
      },
      required: true
    },
    model: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      videoElm: null,
      initial: true,
      //控制封面的显示
      showToolbox: false,
      //控制控制器和标题的显示
      // 视频容器元素
      player: {
        $player: null,
        pos: null
      },
      // progress进度条元素
      progressBar: {
        progressElm: null,
        // 进度条DOM对象
        pos: null
      },
      // video控制显示设置
      videoSet: {
        loaded: 0,
        // 缓存长度
        displayTime: '00:00',
        // 进度时间
        totalTime: '00:00',
        // 总时间
        progress: {
          width: 0,
          // 进度条长度
          current: 0 // 进度条当前位置

        }
      },
      state: {
        controlShow: true,
        vol: 0.5,
        //音量
        currentTime: 0,
        //当前时间
        fullScreen: false,
        playing: false,
        //是否正在播放
        isLoading: false,
        isEnd: false,
        isError: false,
        isMuted: false
      },
      showTouchMask: false
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      return this.options.disabled;
    }
  },
  watch: {
    sources: {
      handler: function handler(newValue, oldValue) {
        var _this = this;

        if (newValue && oldValue && newValue != oldValue) {
          this.$nextTick(function () {
            _this.videoElm.load();
          });
        }
      },
      immediate: true
    },
    options: {
      handler: function handler(val) {
        this.state.isMuted = val.muted ? val.muted : false;
      },
      immediate: true
    } // model: {
    //     handler(val) {
    //         if (val) {
    //             if (val == 'custom') {
    //                 this.state.controlShow = false;
    //                 this.showToolbox = this.options.controls ? true : false
    //             }
    //         } else {
    //             this.showToolbox = false;
    //             this.state.controlShow = this.options.controls ? true : false
    //         }
    //     },
    //     immediate: true
    // }

  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this2 = this;

      this.videoElm = this.$el.getElementsByTagName('video')[0];

      if (this.options.autoplay) {
        this.videoElm.play();
      }

      if (this.options.touchPlay) {
        this.showTouchMask = true;
      }

      if (this.options.playsinline) {
        this.videoElm.setAttribute('playsinline', this.options.playsinline);
        this.videoElm.setAttribute('webkit-playsinline', this.options.playsinline);
        this.videoElm.setAttribute('x5-playsinline', this.options.playsinline);
        this.videoElm.setAttribute('x5-video-player-type', 'h5');
        this.videoElm.setAttribute('x5-video-player-fullscreen', false);
      }

      this.volumeHandle();

      if (this.showToolbox) {
        this.customerInit();
      } else {
        this.videoElm.addEventListener('play', function () {
          _this2.state.playing = true;

          _this2.$emit('play', _this2.videoElm);
        });
        this.videoElm.addEventListener('pause', function () {
          _this2.state.playing = false;

          _this2.$emit('pause', _this2.videoElm);
        });
        this.videoElm.addEventListener('ended', this.playEnded);
        this.videoElm.addEventListener('timeupdate', throttle(this.getPlayTime, 100, 1));
      }
    },
    customerInit: function customerInit() {
      var $player = this.$el;
      var $progress = this.$el.getElementsByClassName('progress')[0]; // 播放器位置

      this.player.$player = $player;
      this.progressBar.progressElm = $progress; // this.player.pos = $player.getBoundingClientRect();

      this.progressBar.pos = $progress.getBoundingClientRect();
      this.videoSet.progress.width = Math.round($progress.getBoundingClientRect().width);
    },
    play: function play() {
      var _this3 = this;

      if (this.options.autoplay && this.options.disabled) {
        this.state.playing = true; // this.state.controlShow = false

        return false;
      }

      this.state.playing = !this.state.playing;

      if (this.videoElm) {
        // 播放状态
        if (this.state.playing) {
          try {
            this.videoElm.play(); // 监听缓存进度

            this.videoElm.addEventListener('progress', function (e) {
              _this3.getLoadTime();
            }); // 监听播放进度

            this.videoElm.addEventListener('timeupdate', throttle(this.getPlayTime, 100, 1)); // 监听结束

            this.videoElm.addEventListener('ended', this.playEnded);
            this.$emit('play', this.videoElm);
          } catch (e) {
            // 捕获url异常出现的错误
            this.handleError();
          }
        } // 停止状态
        else {
            this.videoElm.pause();
            this.$emit('pause', this.videoElm);
          }
      }
    },
    // 音量控制
    volumeHandle: function volumeHandle() {
      this.state.vol = this.options.volume;
    },
    // 静音控制
    handleMuted: function handleMuted() {
      this.state.isMuted = !this.state.isMuted;
      this.videoElm.muted = this.state.isMuted;
    },
    playEnded: function playEnded() {
      this.state.playing = false;
      this.state.isEnd = true;
      this.state.controlBtnShow = true;
      this.videoSet.displayTime = '00:00';
      this.videoSet.progress.current = 0;
      this.videoElm.currentTime = 0;
      this.$emit('playend', this.videoElm);
    },
    // 数据加载出错
    handleError: function handleError() {
      // console.log('error')
      this.state.isError = true;
    },
    fullScreen: function fullScreen() {
      if (!this.state.fullScreen) {
        this.state.fullScreen = true;
        this.videoElm.webkitRequestFullScreen();
      } else {
        this.state.fullScreen = false;
        document.webkitCancelFullScreen();
      } // setTimeout(this.initVideo, 200);

    },
    // 获取播放时间
    getPlayTime: function getPlayTime() {
      var percent = this.videoElm.currentTime / this.videoElm.duration;
      this.videoSet.progress.current = Math.round(this.videoSet.progress.width * percent); // 赋值时长

      this.videoSet.totalTime = this.timeFormat(this.videoElm.duration);
      this.videoSet.displayTime = this.timeFormat(this.videoElm.currentTime);
    },
    timeFormat: function timeFormat(t) {
      var h = Math.floor(t / 3600);

      if (h < 10) {
        h = '0' + h;
      }

      var m = Math.floor(t % 3600 / 60);

      if (m < 10) {
        m = '0' + m;
      }

      var s = Math.round(t % 3600 % 60);

      if (s < 10) {
        s = '0' + s;
      }

      var str = '';

      if (h != 0) {
        str = h + ':' + m + ':' + s;
      } else {
        str = m + ':' + s;
      }

      return str;
    },
    // 获取缓存时间
    getLoadTime: function getLoadTime() {
      if (this.videoSet.loaded) this.videoSet.loaded = this.videoElm.buffered.end(0) / this.videoElm.duration * 100;
    },
    getTime: function getTime() {
      var _this4 = this;

      this.videoElm.addEventListener('durationchange', function (e) {// console.log(e);
      });
      this.videoElm.addEventListener('progress', function (e) {
        _this4.videoSet.loaded = (-1 + _this4.videoElm.buffered.end(0) / _this4.videoElm.duration) * 100;
      });
      this.videoSet.len = this.videoElm.duration;
    },
    // 拖动播放进度
    touchSlidSrart: function touchSlidSrart(e) {},
    touchSlidMove: function touchSlidMove(e) {
      var currentX = e.targetTouches[0].pageX;
      var offsetX = currentX - this.progressBar.pos.left; // 边界检测

      if (offsetX <= 0) {
        offsetX = 0;
      }

      if (offsetX >= this.videoSet.progress.width) {
        offsetX = this.videoSet.progress.width;
      }

      this.videoSet.progress.current = offsetX;
      var percent = this.videoSet.progress.current / this.videoSet.progress.width;
      this.videoElm.duration && this.setPlayTime(percent, this.videoElm.duration);
    },
    touchSlidEnd: function touchSlidEnd(e) {
      var currentX = e.changedTouches[0].pageX;
      var offsetX = currentX - this.progressBar.pos.left;
      this.videoSet.progress.current = offsetX; // 这里的offsetX都是正数

      var percent = offsetX / this.videoSet.progress.width;
      this.videoElm.duration && this.setPlayTime(percent, this.videoElm.duration);
    },
    // 设置手动播放时间
    setPlayTime: function setPlayTime(percent, totalTime) {
      this.videoElm.currentTime = Math.floor(percent * totalTime);
    },
    // 点击重新加载
    retry: function retry() {
      // console.log('error');
      this.state.isError = false;
      this.init();
    }
  },
  beforeDestroy: function beforeDestroy() {}
};