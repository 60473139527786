//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-hor-scroll',
  props: {
    stretch: {
      type: Number,
      default: 40
    },
    scrollTo: {
      type: Number,
      default: 1
    },
    listWidth: {
      type: Number,
      default: 0
    }
  },
  watch: {
    scrollTo: function scrollTo(val) {
      if (typeof val === 'number' && !isNaN(val) && val <= 0) {
        this.setTransform(val, null, 500);
        this.$emit('scrollToCbk');
      }
    }
  },
  data: function data() {
    return {
      touchParams: {
        startX: 0,
        endX: 0,
        startY: 0,
        endY: 0,
        startTime: 0,
        endTime: 0
      },
      transformX: 0,
      scrollDistance: 0,
      timer: null,
      isShowLoadMore: false,
      isFirstShow: false
    };
  },
  methods: {
    isShow: function isShow() {
      var wrapH = this.listWidth ? this.listWidth : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      var listH = this.$refs.list.offsetWidth;

      if (wrapH <= listH) {
        this.isShowLoadMore = true;
      } else {
        this.isShowLoadMore = false;
      }
    },
    setTransform: function setTransform() {
      var translateX = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var type = arguments.length > 1 ? arguments[1] : undefined;
      var time = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 500;
      var unit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'px';
      this.scrollDistance = translateX;
      translateX = translateX + unit;

      if (type === 'end') {
        this.$refs.list.style.webkitTransition = "transform ".concat(time, "ms cubic-bezier(0.19, 1, 0.22, 1)");
      } else {
        this.$refs.list.style.webkitTransition = '';
      }

      this.$refs.list.style.webkitTransform = "translate3d(".concat(translateX, ", 0, 0)");
    },
    setMove: function setMove(move, type, time) {
      var updateMove = move + this.transformX;
      var w = this.listWidth ? this.listWidth : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      var offsetWidth = this.$refs.list.offsetWidth;

      if (type === 'end') {
        if (updateMove > 0) {
          updateMove = 0;
        } else if (updateMove < -offsetWidth + w) {
          if (-offsetWidth + w <= 0) {
            updateMove = -offsetWidth + w;
          } else {
            updateMove = 0;
          }
        }

        this.setTransform(updateMove, type, time);
      } else {
        var maxMove = -offsetWidth + w;

        if (updateMove > 0 && updateMove > this.stretch) {
          updateMove = this.stretch;
        } else if (updateMove < maxMove - this.stretch) {
          if (maxMove <= 0) {
            updateMove = maxMove - this.stretch;
          } else {
            updateMove = updateMove < -this.stretch ? -this.stretch : updateMove;
          }
        }

        this.setTransform(updateMove, null, null);
      }
    },
    touchStart: function touchStart(event) {
      // event.preventDefault();
      var changedTouches = event.changedTouches[0];
      this.touchParams.startX = changedTouches.pageX;
      this.touchParams.startY = changedTouches.pageY;
      this.touchParams.startTime = event.timestamp || Date.now();
      this.transformX = this.scrollDistance;
    },
    touchEvent: function touchEvent(changedTouches, callback) {
      this.touchParams.lastX = changedTouches.pageX;
      this.touchParams.lastY = changedTouches.pageY;
      var moveY = this.touchParams.lastY - this.touchParams.startY;
      var move = this.touchParams.lastX - this.touchParams.startX;

      if (!(Math.abs(move) > 20 && Math.abs(move) > Math.abs(moveY))) {
        return false;
      } else {
        var w = this.listWidth ? this.listWidth : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        var maxMove = -this.$refs.list.offsetWidth + w;
        callback && callback(move, maxMove, moveY);
      }
    },
    touchMove: function touchMove(event) {
      var _this = this;

      //event.preventDefault();
      var changedTouches = event.changedTouches[0];
      this.touchParams.lastTime = event.timestamp || Date.now();
      var moveTime = this.touchParams.lastTime - this.touchParams.startTime;
      this.touchEvent(changedTouches, function (move, maxMove, moveY) {
        event.preventDefault();

        if (event.cancelable) {
          event.preventDefault();
        }

        if (move > 0 && _this.isFirstShow) {
          _this.isFirstShow = false;
        }

        _this.setMove(move);
      });
    },
    touchEnd: function touchEnd(event) {
      var _this2 = this;

      event.stopPropagation();
      var changedTouches = event.changedTouches[0];
      this.touchParams.lastTime = event.timestamp || Date.now();
      var moveTime = this.touchParams.lastTime - this.touchParams.startTime;
      this.touchEvent(changedTouches, function (move, maxMove) {
        //if (moveTime <= 300) {
        if (Math.abs(move) > 100) {
          move = move * 1.5;
        } // 释放跳转之类


        if (move < 0 && move + _this2.transformX < maxMove - 20 && _this2.isFirstShow) {
          _this2.$emit('jump');
        }

        if (!_this2.isFirstShow && move < 0 && move + _this2.transformX < maxMove && _this2.$slots.more) {
          _this2.isFirstShow = true; //move = maxMove - this.transformX;
        }

        if (moveTime <= 300) {
          moveTime = moveTime + 500;

          _this2.setMove(move, 'end', moveTime);
        } else {
          _this2.setMove(move, 'end');
        }
      });
    }
  },
  mounted: function mounted() {
    var _this3 = this;

    this.$nextTick(function () {
      _this3.isShow(); // 监听


      _this3.$el.addEventListener('touchstart', _this3.touchStart);

      _this3.$el.addEventListener('touchmove', _this3.touchMove);

      _this3.$el.addEventListener('touchend', _this3.touchEnd);
    });
  },
  beforeDestroy: function beforeDestroy() {
    // 移除监听
    this.$el.removeEventListener('touchstart', this.touchStart);
    this.$el.removeEventListener('touchmove', this.touchMove);
    this.$el.removeEventListener('touchend', this.touchEnd);
    clearTimeout(this.timer);
  }
};