import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import nutpicker from '../picker/picker.vue';
import '../picker/picker.scss';
import Utils from '../../utils/date.js';
export default {
  name: 'nut-datepicker',
  props: {
    type: {
      type: String,
      default: 'date'
    },
    isSetSecond: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    isUse12Hours: {
      type: Boolean,
      default: false
    },
    isAm: {
      type: Boolean,
      default: true
    },
    minuteStep: {
      type: Number,
      default: 1
    },
    secondStep: {
      type: Number,
      default: 1
    },
    isShowChinese: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: null
    },
    defaultValue: {
      type: String,
      default: null
    },
    startDate: {
      type: String,
      default: '2000-01-01'
    },
    endDate: {
      type: String,
      default: Utils.date2Str(new Date())
    },
    startHour: {
      type: Number | String,
      default: 0
    },
    endHour: {
      type: Number | String,
      default: 23
    }
  },
  data: function data() {
    return {
      listData: [],
      defaultValueData: null,
      startDateArr: null,
      endDateArr: null,
      startYear: null,
      endYear: null,
      cacheDefaultData: [],
      cacheListData: [],
      updateYear: null,
      updateMonth: null,
      updateDay: null,
      updateHour: null,
      use12Hours: ['上午', '下午'],
      chinese: !this.isShowChinese ? new Array(6).fill('') : this.type == 'time' ? this.isUse12Hours ? ['时', '分', ''] : ['时', '分', '秒'] : ['年', '月', '日', '时', '分']
    };
  },
  components: _defineProperty({}, nutpicker.name, nutpicker),
  created: function created() {
    this.init();
  },
  computed: {
    dateRange: function dateRange() {
      var startDate = this.startDate,
          endDate = this.endDate,
          defaultValue = this.defaultValue;
      return {
        startDate: startDate,
        endDate: endDate,
        defaultValue: defaultValue
      };
    }
  },
  watch: {
    dateRange: function dateRange(newValue, oldValue) {
      this.init();
    }
  },
  methods: {
    init: function init() {
      if (this.startDate && Utils.isDateString(this.startDate)) {
        this.startDateArr = Utils.getDateArr(this.startDate);
      } else {
        this.startDateArr = Utils.getDateArr('2000-01-01');
      }

      if (this.endDate && Utils.isDateString(this.endDate)) {
        this.endDateArr = Utils.getDateArr(this.endDate);
      } else {
        this.endDateArr = Utils.date2Str(new Date());
      } // 结束时间小于开始时间，结束时间重置为开始时间


      if (Utils.compareDateArr(this.endDateArr, this.startDateArr)) {
        // this.endDate = this.startDate;
        this.endDateArr = this.startDateArr;
      } // this.startDateArr = this.startDate.replace(/-/g, '/').split('/');
      // this.endDateArr = this.endDate.replace(/-/g, '/').split('/');


      this.initListData();
    },
    initListData: function initListData() {
      this.resetDefaultValue();

      switch (this.type) {
        case 'date':
          this.cacheListData = [this.getYears(), this.getMonths(this.defaultValueData[0]), this.getDays(this.defaultValueData[0], this.defaultValueData[1])].concat();
          break;

        case 'datetime':
          this.cacheListData = [this.getYears(), this.getMonths(this.defaultValueData[0]), this.getDays(this.defaultValueData[0], this.defaultValueData[1]), this.getChangeHours(this.defaultValueData[0], this.defaultValueData[1], this.defaultValueData[2]), this.getChangeMinutes(this.defaultValueData[0], this.defaultValueData[1], this.defaultValueData[2], this.defaultValueData[3])].concat();
          break;

        case 'time':
          this.cacheListData = [this.getHours(), this.getMinutes()].concat();

          if (this.isUse12Hours) {
            this.cacheListData = [].concat(_toConsumableArray(this.cacheListData), [this.use12Hours]);
          } else {
            this.cacheListData = this.isSetSecond ? [].concat(_toConsumableArray(this.cacheListData), [this.getSeconds()]) : _toConsumableArray(this.cacheListData);
          }

          break;
      }

      this.listData = _toConsumableArray(this.cacheListData);
    },
    resetDefaultValue: function resetDefaultValue() {
      var cacheDefaultValue = null;

      if (!this.defaultValue || !Utils.isDateString(this.defaultValue)) {
        switch (this.type) {
          case 'time':
            cacheDefaultValue = this.isSetSecond ? "00:00:00" : "00:00";
            break;

          case 'date':
          case 'datetime':
            cacheDefaultValue = "".concat(this.startDateArr[0], "-").concat(this.startDateArr[1], "-").concat(this.startDateArr[2], " ").concat(this.startDateArr[3], ":").concat(this.startDateArr[4]);
            break;
        }
      } else {
        cacheDefaultValue = this.defaultValue;
      }

      var splitArr = cacheDefaultValue.split(' ');

      if (this.type === 'time') {
        var timeArr = splitArr[0].split(':');
        this.isUse12Hours && timeArr.push(this.isAm ? this.use12Hours[0] : this.use12Hours[1]);
        this.cacheDefaultData = this.getCacheData(timeArr);
      } else {
        var cacheData = _toConsumableArray(splitArr[0].replace(/-/g, '/').split('/'));

        if (this.type == 'datetime') {
          cacheData = [].concat(_toConsumableArray(cacheData), _toConsumableArray(splitArr[1].split(':')));
        }

        this.cacheDefaultData = this.getCacheData(cacheData);
        this.updateYear = this.cacheDefaultData[0];
        this.updateMonth = this.cacheDefaultData[1];
        this.updateDay = this.cacheDefaultData[2];
        this.updateHour = this.cacheDefaultData[3];
      }

      this.defaultValueData = _toConsumableArray(this.cacheDefaultData);
    },
    getCacheData: function getCacheData(data) {
      var _this = this;

      var cacheData = [];
      data.map(function (item, index) {
        item < 10 && (item = item.replace(/^0/g, ''));
        cacheData.push("".concat(item).concat(_this.chinese[index]));
      });
      return cacheData;
    },
    getYears: function getYears() {
      var cacheYears = [];

      for (var i = this.startDateArr[0]; i <= this.endDateArr[0]; i++) {
        cacheYears.push("".concat(i).concat(this.chinese[0]));
      }

      return cacheYears;
    },
    getMonths: function getMonths(year) {
      year = this.removeChinese(year);
      var cacheMonths = [];

      for (var i = 1; i <= 12; i++) {
        if (!(year == this.startDateArr[0] && i < this.startDateArr[1]) && !(year == this.endDateArr[0] && i > this.endDateArr[1])) {
          cacheMonths.push("".concat(i).concat(this.chinese[1]));
        }
      }

      return cacheMonths;
    },
    getDays: function getDays(year, month) {
      var _this2 = this;

      year = this.removeChinese(year);
      month = this.removeChinese(month);
      var days = Array.from(Array(Utils.getMonthDays(year, month)), function (v, k) {
        if (!(year == _this2.startDateArr[0] && month == parseInt(_this2.startDateArr[1]) && k + 1 < parseInt(_this2.startDateArr[2])) && !(year == _this2.endDateArr[0] && month == parseInt(_this2.endDateArr[1]) && k + 1 > parseInt(_this2.endDateArr[2]))) {
          return "".concat(k + 1).concat(_this2.chinese[2]);
        }
      });
      return days.filter(function (item) {
        return item;
      });
    },
    getChangeHours: function getChangeHours(year, month, day) {
      var _this3 = this;

      year = this.removeChinese(year);
      month = this.removeChinese(month).padStart(2, '0');
      day = this.removeChinese(day).padStart(2, '0');
      var hours = Array.from(Array(24).keys()).map(function (hour) {
        var startEqualState = year == _this3.startDateArr[0] && month == _this3.startDateArr[1] && day == _this3.startDateArr[2];
        var endEqualState = year == _this3.endDateArr[0] && month == _this3.endDateArr[1] && day == _this3.endDateArr[2];
        var startHour = _this3.startDateArr[3],
            endHour = _this3.endDateArr[3];
        var resHour = undefined;

        if (startEqualState && endEqualState) {
          if (hour >= parseInt(startHour) && hour <= parseInt(endHour)) {
            resHour = hour;
          }
        } else if (startEqualState) {
          if (hour >= parseInt(startHour)) {
            resHour = hour;
          }
        } else if (endEqualState) {
          if (hour <= parseInt(endHour)) {
            resHour = hour;
          }
        } else {
          resHour = hour;
        }

        if (resHour == 0) {
          resHour = '0';
        }

        return resHour ? "".concat(resHour).concat(_this3.chinese[3]) : undefined;
      });
      return hours.filter(function (item) {
        return item;
      });
    },
    getChangeMinutes: function getChangeMinutes(year, month, day, hour) {
      var _this4 = this;

      year = this.removeChinese(year);
      month = this.removeChinese(month).padStart(2, '0');
      day = this.removeChinese(day).padStart(2, '0');
      hour = this.removeChinese(hour).padStart(2, '0');
      var minutes = Array.from(Array(60).keys()).map(function (minute) {
        var startEqualState = year == _this4.startDateArr[0] && month == _this4.startDateArr[1] && day == _this4.startDateArr[2] && hour == _this4.startDateArr[3];
        var endEqualState = year == _this4.endDateArr[0] && month == _this4.endDateArr[1] && day == _this4.endDateArr[2] && hour == _this4.endDateArr[3];
        var startMinute = _this4.startDateArr[4],
            endMinute = _this4.endDateArr[4];
        var resMinute = undefined;

        if (startEqualState && endEqualState) {
          if (minute >= parseInt(startMinute) && minute <= parseInt(endMinute)) {
            resMinute = minute;
          }
        } else if (startEqualState) {
          if (minute >= parseInt(startMinute)) {
            resMinute = minute;
          }
        } else if (endEqualState) {
          if (minute <= parseInt(endMinute)) {
            resMinute = minute;
          }
        } else {
          resMinute = minute;
        }

        if (resMinute == 0) {
          resMinute = '0';
        }

        return resMinute % _this4.minuteStep == 0 ? "".concat(resMinute).concat(_this4.chinese[4]) : undefined;
      });
      return minutes.filter(function (item) {
        return item;
      });
    },
    getHours: function getHours() {
      var _this5 = this;

      var endHour = this.endHour;

      if (this.isUse12Hours) {
        endHour = 11;
      }

      var hours = Array.from(Array(parseInt(endHour) + 1), function (v, k) {
        if (_this5.isUse12Hours && k == 0) {
          k = 12;
        }

        if (k >= _this5.startHour) {
          return "".concat(k).concat(_this5.type == 'time' ? _this5.chinese[0] : _this5.chinese[3]);
        }
      });
      return hours.filter(function (item) {
        return item;
      });
    },
    getMinutes: function getMinutes() {
      var _this6 = this;

      var minutes = Array.from(Array(60), function (v, k) {
        if (k == 0 || k % _this6.minuteStep == 0) {
          return "".concat(k).concat(_this6.type == 'time' ? _this6.chinese[1] : _this6.chinese[4]);
        }
      });
      return minutes.filter(function (item) {
        return item;
      });
    },
    getSeconds: function getSeconds() {
      var _this7 = this;

      var seconds = Array.from(Array(60), function (v, k) {
        if (k == 0 || k % _this7.secondStep == 0) {
          return "".concat(k).concat(_this7.type == 'time' ? _this7.chinese[2] : _this7.chinese[5]);
        }
      });
      return seconds.filter(function (item) {
        return item;
      });
    },
    setChooseValue: function setChooseValue(chooseData) {
      var _this8 = this;

      var cacheChooseData = [];
      chooseData.map(function (item, index) {
        if (_this8.isUse12Hours && _this8.type == 'time' && index == 2) {
          cacheChooseData.push(item);
        } else {
          cacheChooseData.push(Utils.getNumTwoBit(_this8.removeChinese(item)));
        }
      });

      if (/^date/.test(this.type)) {
        switch (this.type) {
          case 'date':
            cacheChooseData.push("".concat(cacheChooseData[0], "-").concat(cacheChooseData[1], "-").concat(cacheChooseData[2]));
            break;

          case 'datetime':
            cacheChooseData.push("".concat(cacheChooseData[0], "-").concat(cacheChooseData[1], "-").concat(cacheChooseData[2], " ").concat(cacheChooseData[3], ":").concat(cacheChooseData[4]));
            break;
        }

        var week = Utils.getWhatDay(cacheChooseData[0], cacheChooseData[1], cacheChooseData[2]);
        cacheChooseData.push(week);
      } else {
        cacheChooseData.push("".concat(cacheChooseData[0], ":").concat(cacheChooseData[1]).concat(this.isSetSecond ? ':' + cacheChooseData[2] : ''));
      }

      this.$emit('choose', cacheChooseData);
    },
    removeChinese: function removeChinese(value) {
      return value.toString().replace(/([^\u0000-\u00FF])/g, '');
    },
    updateLinkage: function updateLinkage(self, index, value, chooseValue, cacheValueData) {
      if (!value || !cacheValueData[index] || this.type == 'time') {
        return false;
      }

      value = this.removeChinese(value);

      switch (index) {
        case 1:
          //year
          this.updateYear = value;
          this.listData.splice(index, 1, this.getMonths(value));
          chooseValue = chooseValue ? chooseValue : cacheValueData[index];
          var curMonthsData = this.listData[index];

          if (curMonthsData.indexOf(chooseValue) === -1) {
            chooseValue = curMonthsData[0];
          }

          self && self.updateChooseValue(self, index, chooseValue);
          this.updateLinkage(self, 2, cacheValueData[index], null, cacheValueData);
          break;

        case 2:
          //month
          this.updateMonth = value;
          this.listData.splice(index, 1, this.getDays(parseInt(this.updateYear), value));
          chooseValue = chooseValue ? chooseValue : cacheValueData[index];
          var curDaysData = this.listData[index];

          if (curDaysData.indexOf(chooseValue) === -1) {
            if (curDaysData.length < 28) {
              chooseValue = curDaysData[0];
            } else {
              var curChooseDay = parseInt(this.removeChinese(chooseValue));
              var days = curDaysData.length;
              chooseValue = (curChooseDay > days ? days : curChooseDay) + this.chinese[2];
            }
          }

          self && self.updateChooseValue(self, index, chooseValue);
          this.updateLinkage(self, 3, cacheValueData[index], null, cacheValueData);
          break;

        case 3:
          //day
          this.updateDay = value;
          this.listData.splice(index, 1, this.getChangeHours(parseInt(this.updateYear), parseInt(this.updateMonth), value));
          chooseValue = chooseValue ? chooseValue : cacheValueData[index];
          var curHoursData = this.listData[index];

          if (curHoursData.indexOf(chooseValue) === -1) {
            chooseValue = curHoursData[0];
          }

          self && self.updateChooseValue(self, index, chooseValue);
          this.updateLinkage(self, 4, cacheValueData[index], null, cacheValueData);
          break;

        case 4:
          //hour
          this.updateHour = value;
          this.listData.splice(index, 1, this.getChangeMinutes(parseInt(this.updateYear), parseInt(this.updateMonth), parseInt(this.updateDay), parseInt(this.updateHour), value));
          chooseValue = chooseValue ? chooseValue : cacheValueData[index];
          var curMinuteData = this.listData[index];

          if (curMinuteData.indexOf(chooseValue) === -1) {
            chooseValue = curMinuteData[0];
          }

          self && self.updateChooseValue(self, index, chooseValue);
      }
    },
    updateChooseValue: function updateChooseValue(self, index, value, cacheValueData) {
      switch (index) {
        case 0: //year

        case 1: //month

        case 2: //day

        case 3:
          //hour
          this.updateLinkage(self, index + 1, value, null, cacheValueData);
          break;

        case 4:
          //min
          break;
      }
    },
    closeUpdateChooseValue: function closeUpdateChooseValue(self, chooseData) {
      this.updateLinkage(self, 1, chooseData[0], chooseData[1], chooseData);
    },
    switchPicker: function switchPicker(param) {
      this.$emit('close');
    }
  }
};