//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-elevator',
  props: {
    otherHeight: {
      type: Number,
      default: '60'
    },
    dataArray: {
      type: Array,
      required: true
    },
    navHeight: {
      type: Number,
      default: '40'
    },
    hiddenTime: {
      type: Number,
      default: '10'
    },
    showIndicator: {
      type: Boolean,
      default: true
    },
    initIndex: {
      type: Number,
      default: '0'
    }
  },
  computed: {
    navListHeight: function navListHeight() {
      return this.navHeight;
    }
  },
  data: function data() {
    return {
      wrapHeight: '40',
      currTitle: '',
      currBox: false
    };
  },
  watch: {
    dataArray: function dataArray(val) {
      var _this = this;

      if (val.length > 0) {
        this.$nextTick(function () {
          _this.initPage();
        });
      }
    }
  },
  mounted: function mounted() {
    if (this.dataArray.length > 0) {
      this.initPage();
    }
  },
  methods: {
    initPage: function initPage() {
      var innerHeight = document.documentElement.clientHeight;
      this.wrapHeight = innerHeight - this.otherHeight;
      var initIndex = this.dataArray[this.initIndex].title;
      document.getElementById(initIndex).scrollIntoView();
    },
    getStyle: function getStyle(element, attr) {
      if (element.currentStyle) {
        return element.currentStyle[attr];
      } else {
        return getComputedStyle(element, false)[attr];
      }
    },
    getFontSize: function getFontSize() {
      var htmlDom = document.getElementsByTagName('html')[0];
      var bili = this.getStyle(htmlDom, 'fontSize');
      return bili.substring(0, bili.length - 2);
    },
    clickNav: function clickNav(title, index) {
      var _this2 = this;

      this.currBox = true;
      this.currTitle = title;
      setTimeout(function () {
        _this2.currBox = false;
      }, this.hiddenTime);
      this.moveFun(title, index);
    },
    clickList: function clickList(list, item) {
      this.$emit('clickList', list, item);
    },
    moveFun: function moveFun(title, index) {
      var titleBox = document.getElementById(title);
      titleBox.scrollIntoView();
    },
    onPointerEnd: function onPointerEnd(e) {
      var _this3 = this;

      var dataArrayLength = this.dataArray.length;
      var navHeight = document.getElementById('nut-elevator-nav').clientHeight;
      var navTop = document.getElementById('nut-elevator-nav').offsetTop;
      var navOffsetTop = navTop - navHeight / 2; //nav距离顶部的距离

      var eTop = e.type.indexOf('touch') !== -1 ? e.changedTouches[0].clientY : e.clientY;
      var navIndex = parseInt((eTop - navOffsetTop) / this.navHeight);
      setTimeout(function () {
        _this3.currBox = false;
      }, this.hiddenTime);

      if (navIndex < dataArrayLength && navIndex >= 0) {
        this.$emit('clickNav', this.dataArray[navIndex].title, navIndex);
      }
    },
    onPointerMove: function onPointerMove(e) {
      e.preventDefault();
      var dataArrayLength = this.dataArray.length;
      var navHeight = document.getElementById('nut-elevator-nav').clientHeight;
      var navTop = document.getElementById('nut-elevator-nav').offsetTop;
      var navOffsetTop = navTop - navHeight / 2; //nav距离顶部的距离

      var eTop = e.type.indexOf('touch') !== -1 ? e.touches[0].clientY : e.clientY;
      var navIndex = parseInt((eTop - navOffsetTop) / this.navHeight);

      if (navIndex < dataArrayLength && navIndex >= 0) {
        this.moveFun(this.dataArray[navIndex].title, navIndex);
        this.currBox = true;
        this.currTitle = this.dataArray[navIndex].title;
      }
    }
  }
};