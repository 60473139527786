import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Vue from 'vue';
import overlayComponent from './overlay.vue';
var modalStack = [];
var _zIndex = 2000;
var overlay;
var overlayManager = {
  lockCount: 0,

  get topStack() {
    return modalStack[modalStack.length - 1];
  },

  getZIndex: function getZIndex(id) {
    if (!id) return ++_zIndex;
    var overlay = modalStack.find(function (res) {
      return res.config.id === id;
    });

    if (overlay) {
      return overlay.config.zIndex;
    } else {
      return ++_zIndex;
    }
  },
  updateOverlay: function updateOverlay() {
    var clickHandle = overlayManager.clickHandle,
        topStack = overlayManager.topStack;

    if (!overlay) {
      overlay = mount(overlayComponent, {
        nativeOn: {
          click: clickHandle
        }
      });
    }

    if (topStack) {
      var vm = topStack.vm,
          config = topStack.config;
      var el = vm.$el;
      el && el.parentNode && el.parentNode.nodeType !== 11 ? el.parentNode.appendChild(overlay.$el) : document.body.appendChild(overlay.$el);

      _extends(overlay, config, {
        value: true
      });
    } else {
      overlay.value = false;
    }
  },
  //打开遮罩层
  openModal: function openModal(vm, config) {
    var zIndex = config.zIndex,
        duration = config.duration,
        overlayClass = config.overlayClass,
        overlayStyle = config.overlayStyle,
        id = config.id;
    modalStack.push({
      vm: vm,
      config: {
        id: id,
        zIndex: zIndex,
        duration: duration,
        overlayClass: overlayClass,
        overlayStyle: overlayStyle
      }
    });
    overlayManager.updateOverlay();
  },
  clickHandle: function clickHandle() {
    var topStack = overlayManager.topStack; //防止多次点击

    if (modalStack.length && topStack.vm.closeOnClickOverlay) {
      topStack.vm.$emit('click-overlay');
      topStack.vm.close();
    }
  },
  closeOverlay: function closeOverlay(vm) {
    if (modalStack.length) {
      if (overlayManager.topStack.vm === vm) {
        modalStack.pop();
        overlayManager.updateOverlay();
      } else {
        modalStack = modalStack.filter(function (item) {
          return item.vm !== vm;
        });
      }
    }
  }
};
var overlayProps = {
  value: {
    type: Boolean,
    default: false
  },
  overlay: {
    type: Boolean,
    default: true
  },
  lockScroll: {
    type: Boolean,
    default: true
  },
  duration: {
    type: Number,
    default: 0.3
  },
  closeOnClickOverlay: {
    type: Boolean,
    default: true
  },
  overlayClass: {
    type: String,
    default: ''
  },
  overlayStyle: {
    type: Object,
    default: function _default() {
      return null;
    }
  },
  zIndex: {
    type: Number
  }
};

function mount(Component, data) {
  var instance = new Vue({
    props: Component.props,
    render: function render(h) {
      return h(Component, _objectSpread({
        props: this.$props
      }, data));
    }
  }).$mount();
  return instance;
}

function getProps() {
  var _this = this;

  if (!this) return {};
  var obj = {};
  Object.keys(overlayProps).forEach(function (res) {
    obj[res] = _this[res];
  });
  return obj;
}

export { overlayManager, overlayProps, getProps };