//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'skeletonSquare',
  data: function data() {
    return {};
  },
  props: {
    backColor: {
      type: String,
      default: '#efefef'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '12px'
    },
    margin: {
      type: String,
      default: '0 0 0 0'
    },
    count: {
      type: Number,
      default: 1
    }
  }
};