import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-numberkeyboard',
  props: {
    title: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default'
    },
    customKey: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: String,
      default: ''
    },
    maxlength: {
      type: [Number, String],
      default: 6
    }
  },
  model: {
    prop: 'value',
    event: 'update:value'
  },
  computed: {
    keysList: function keysList() {
      if (this.type == 'rightColumn' || this.title != '') {
        return this.genCustomKeys();
      }

      return this.defaultKey();
    }
  },
  data: function data() {
    return {
      once: 0,
      keys: [],
      clickKeyIndex: undefined
    };
  },
  beforeDestroy: function beforeDestroy() {
    document.getElementsByTagName('body')[0].removeEventListener('click', function () {});
  },
  mounted: function mounted() {
    var that = this;
    this.$nextTick(function () {
      var targetArea = that.$el;
      document.getElementsByTagName('body')[0].addEventListener('click', function (event) {
        var e = event || window.event;
        var elem = e.target;

        if (targetArea.contains(elem)) {// console.log("在区域内");
        } else {
          // console.log("在区域外");
          if (that.once == 0 && that.visible) {
            that.once += 1;
            return false;
          }

          that.closeBoard();
        }
      });
    });
  },
  methods: {
    getBasicKeys: function getBasicKeys() {
      var keys = [];

      for (var i = 1; i <= 9; i++) {
        keys.push({
          id: i,
          type: 'number'
        });
      }

      return keys;
    },
    defaultKey: function defaultKey() {
      return [].concat(_toConsumableArray(this.getBasicKeys()), [{
        id: 'lock',
        type: 'lock'
      }, {
        id: 0,
        type: 'number'
      }, {
        id: 'delete',
        type: 'delete'
      }]);
    },
    genCustomKeys: function genCustomKeys() {
      var keys = this.getBasicKeys();
      var customKey = this.customKey;
      var customKeys = Array.isArray(customKey) ? customKey : [customKey];

      if (customKeys.length > 2) {
        customKeys = [customKeys[0], customKeys[1]];
      }

      if (customKeys.length === 1) {
        if (this.title) {
          keys.push({
            id: customKeys[0],
            type: 'custom'
          }, {
            id: 0,
            type: 'number'
          }, {
            id: 'delete',
            type: 'delete'
          });
        } else {
          keys.push({
            id: 0,
            type: 'number'
          }, {
            id: customKeys[0],
            type: 'custom'
          });
        }
      } else if (customKeys.length === 2) {
        keys.push({
          id: customKeys[0],
          type: 'custom'
        }, {
          id: 0,
          type: 'number'
        }, {
          id: customKeys[1],
          type: 'custom'
        });

        if (this.title) {
          keys.push({
            id: 'delete',
            type: 'delete'
          });
        }
      }

      return keys;
    },
    afterEnter: function afterEnter() {
      this.$emit('enter');
    },
    afterLeave: function afterLeave() {
      this.$emit('leave');
    },
    onTouchstart: function onTouchstart(item, event) {
      event.stopPropagation();
      this.clickKeyIndex = item.id;

      if (item.type == 'number' || item.type == 'custom') {
        this.$emit('input', item.id);

        if (this.value.length < this.maxlength) {
          this.$emit('update:value', this.value + item.id);
        }
      }

      if (item.type == 'lock') {
        this.closeBoard();
      }

      if (item.type == 'delete') {
        this.$emit('delete');
        this.$emit('update:value', this.value.slice(0, this.value.length - 1));
      }
    },
    onTouchMove: function onTouchMove(id, event) {
      event.stopPropagation(); // this.clickKeyIndex = id;
    },
    onTouchEnd: function onTouchEnd() {
      this.clickKeyIndex = undefined;
    },
    closeBoard: function closeBoard() {
      if (this.once > 0) {
        this.once = 0;
        this.$emit('close');
      }
    }
  }
};