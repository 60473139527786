//
//
//
//
//
export default {
  name: 'nut-buttongroup',
  props: {
    shape: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      cls: ''
    };
  },
  mounted: function mounted() {
    this.cls = "nut-buttongroup ".concat(this.shape, " ").concat(this.type);
  }
};