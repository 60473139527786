function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-leftslip',
  props: {
    isClickBack: {
      type: Boolean,
      default: true
    },
    rightWidth: {
      type: [Number, String],
      default: '0.8'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      startX: 0,
      startY: 0,
      moveX: 0,
      moveY: 0,
      buttonWidth: 0,
      pageWidth: null,
      startPos: 0,
      startLeft: 0,
      startRightW: 0,
      isOpen: false,
      scrollTop: 0,
      oldScrollTop: 0,
      lock: false
    };
  },
  watch: {// scrollTop(newValue, oldValue) {
    //     setTimeout(() => {
    //         if (newValue == window.scrollY) { //延时执行后当newValue等于window.scrollY，代表滚动结束
    //             console.log('滚动结束');
    //             this.oldScrollTop = newValue; //每次滚动结束后都要给oldScrollTop赋值
    //             this.lock = false
    //         };
    //     }, 20); //必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
    //     if (this.oldScrollTop == oldValue) { //每次滚动开始时oldScrollTop与oldValue相等
    //         console.log('滚动开始');
    //         this.lock = true;
    //     }
    // }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      var _iterator = _createForOfIteratorHelper(_this.$slots.slipbtns),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var slot = _step.value;
          _this.buttonWidth = _this.buttonWidth + slot.elm.offsetWidth;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    });
    this.pageWidth = document.documentElement.clientWidth;
    this.sliderEle = this.$refs.slipItem; // this.handleScroll();
    // document.addEventListener('touchstart', this.touchStart, false);
  },
  beforeDestroy: function beforeDestroy() {
    // 移除监听
    window.removeEventListener('scroll', this.handleScroll());
  },
  methods: {
    handleRestet: function handleRestet() {
      var slip = document.getElementsByClassName('leftslip-open');

      if (slip) {
        this.restSlide();
      }
    },
    handleScroll: function handleScroll() {
      var _this2 = this;

      window.addEventListener('scroll', function () {
        _this2.scrollTop = window.scrollY;
      });
    },
    touchStart: function touchStart(e) {
      if (this.disabled) return false;
      var parentElement = e.currentTarget.parentElement;
      var slip = [];
      slip = document.getElementsByClassName('leftslip-open');

      if (slip) {
        if (parentElement.dataset.type == 1) return;
        this.restSlide();
      }

      if (e.touches.length == 1) {
        this.startX = e.touches[0].pageX;
        this.startY = e.touches[0].pageY;
      }

      var transform = this.sliderEle.style.transform;
      this.startLeft = Number(transform ? transform.split('(')[1].split('px')[0] : 0);
      this.startRightW = this.startLeft < 0 ? Number(this.$refs.right.style.width.split('px')[0]) : 0;
    },
    touchMove: function touchMove(e) {
      if (this.disabled) return false;
      var parentElement = e.currentTarget.parentElement;
      var disX = e.touches[0].pageX - this.startX; // >0 右滑，<0 左滑

      var disY = e.touches[0].pageY - this.startY;

      if (e.touches.length == 1) {
        if (Math.abs(disY) < 5) {
          // console.log(disX, disY)
          if (disX > 0 || disX > 0 && this.startLeft >= 0) {
            //禁止右滑
            return false;
          } else {
            this.doSlide(-this.buttonWidth, true); // 最大滑动距离为右侧宽度
          }
        }
      }
    },
    touchEnd: function touchEnd(e) {
      if (this.disabled) return false;
      var parentElement = e.currentTarget.parentElement;
      var disX = e.changedTouches[0].pageX - this.startX; // >0 右滑，<0 左滑

      var distance; // console.log(1, disX, this.isClickBack)

      if (!this.isClickBack && disX === 0) {
        // 点击时不收起右侧
        // console.log(2, disX, this.isClickBack)
        return false;
      } else {
        if (-disX > 50) {
          // 向左滑动超过阙值时,右侧滑出固定距离
          distance = this.buttonWidth > this.pageWidth ? this.pageWidth * Number(this.rightWidth) : this.buttonWidth;
          parentElement.className = 'nut-leftslip-item leftslip-open';
          parentElement.dataset.type = 1;
        } else {
          // 向左滑动未超过阙值，或向右滑动时，回原位
          distance = 0;
          parentElement.className = 'nut-leftslip-item';
          parentElement.dataset.type = 0;
        } // console.log(3, disX, this.isClickBack)


        this.doSlide(-distance, true);
      }
    },
    doSlide: function doSlide(distance) {
      var animate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.sliderEle.style.transform = "translateX(".concat(distance, "px)");
      this.$refs.right.style.width = -distance + 'px';
      this.sliderEle.style.transition = animate ? 'transform .5s' : 'initial';
      this.$refs.right.style.transition = animate ? 'width .5s' : 'initial';
    },
    restSlide: function restSlide() {
      var listItems = document.querySelectorAll('.nut-leftslip-item.leftslip-open'); // 复位

      for (var i = 0; i < listItems.length; i++) {
        listItems[i].style = 'transform:translateX(0px)';
        listItems[i].dataset.type = 0; //是否展开标志位默认0，左滑展开为1，右滑隐藏为0

        listItems[i].className = 'nut-leftslip-item';
        this.isOpen = false;
      }
    }
  }
};