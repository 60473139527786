//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'row',
  data: function data() {
    return {};
  },
  props: {
    width: {
      type: String,
      default: 'auto'
    },
    padding: {
      type: String,
      default: '0 0 0 0'
    },
    center: {
      type: String,
      default: 'flex-start'
    }
  }
};