import _extends from "@babel/runtime/helpers/extends";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Popup from './../popup/popup.vue';
import Icon from './../icon/icon.vue';
import { TweenMax } from 'gsap';
export default {
  name: 'nut-address',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'custom'
    },
    customAddressTitle: {
      type: String,
      default: '请选择所在地区'
    },
    province: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 省
    city: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 市
    country: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 县
    town: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 镇
    isShowCustomAddress: {
      type: Boolean,
      default: true
    },
    // 是否显示‘选择其他地区’按钮 type=‘exist’ 生效
    existAddress: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 现存地址列表
    existAddressTitle: {
      type: String,
      default: '配送至'
    },
    customAndExistTitle: {
      type: String,
      default: '选择其他地址'
    },
    defaultIcon: {
      // 地址选择列表前 - 默认的图标
      type: String,
      default: require('../../assets/svg/address-location.svg')
    },
    selectedIcon: {
      // 地址选择列表前 - 选中的图标
      type: String,
      default: require('../../assets/svg/tick-red.svg')
    },
    closeBtnIcon: {
      // 关闭弹框按钮 icon
      type: String,
      default: require('../../assets/svg/circle-cross.svg')
    },
    backBtnIcon: {
      // 选择其他地址左上角返回 icon
      type: String,
      default: require('../../assets/svg/arrows-back.svg')
    }
  },
  data: function data() {
    return {
      showPopup: false,
      showModule: 'exist',
      //展示 exist 还是 custom 主要用于‘选择其他地址’
      tabIndex: 0,
      tabName: ['province', 'city', 'country', 'town'],
      regionList: {
        province: this.province,
        city: this.city,
        country: this.country,
        town: this.town
      },
      //省、市、县、镇列表,地址id字符串,地址字符串
      selectedRegion: {
        province: {},
        city: {},
        country: {},
        town: {}
      },
      //已选择的 省、市、县、镇
      selectedExistAddress: {} // 当前选择的地址

    };
  },
  components: {
    'nut-popup': Popup,
    'nut-icon': Icon
  },
  watch: {
    value: function value(newVal, oldVal) {
      this.showPopup = newVal;
    },
    showPopup: function showPopup(newVal, oldVal) {
      if (newVal == false) this.$emit('input', false);

      if (newVal == true) {
        this.showModule = this.type;
      }
    },
    province: function province(newVal, oldVal) {
      this.regionList.province = newVal;
    },
    city: function city(newVal, oldVal) {
      this.regionList.city = newVal;
    },
    country: function country(newVal, oldVal) {
      this.regionList.country = newVal;
    },
    town: function town(newVal, oldVal) {
      this.regionList.town = newVal;
    },
    existAddress: function existAddress(newVal, oldVal) {
      var _this = this;

      this.existAddress = newVal;
      newVal.forEach(function (item, index) {
        if (item.selectedAddress) {
          _this.selectedExistAddress = item;
        }
      });
    }
  },
  mounted: function mounted() {},
  methods: {
    //获取已选地区列表名称
    getTabName: function getTabName(item, index) {
      if (item.name) return item.name;

      if (this.tabIndex < index) {
        return item.name;
      } else {
        return '请选择';
      }
    },
    // 切换下一级列表
    nextAreaList: function nextAreaList(item) {
      // onchange 接收的参数
      var calBack = {
        custom: this.tabName[this.tabIndex]
      };
      this.selectedRegion[this.tabName[this.tabIndex]] = item;

      for (var i = this.tabIndex; i < this.tabName.length - 1; i++) {
        this.selectedRegion[this.tabName[i + 1]] = {};
      }

      if (this.tabIndex < 3) {
        this.tabIndex = this.tabIndex + 1;
        this.lineAnimation(); // 切换下一个

        calBack.next = this.tabName[this.tabIndex];
        calBack.value = item;
        this.$emit('onChange', calBack);
        this.$emit('on-change', calBack);
      } else {
        this.handClose();
      }
    },
    //切换地区Tab
    changeRegionTab: function changeRegionTab(item, key, index) {
      this.tabIndex = index;
      this.lineAnimation();
    },
    // 移动下面的红线
    lineAnimation: function lineAnimation() {
      var _this2 = this;

      var name = 'tab-item-' + this.tabName[this.tabIndex];
      this.$nextTick(function () {
        if (_this2.$refs[name] && _this2.$refs[name][0]) {
          var distance = _this2.$refs[name][0].offsetLeft;
          TweenMax.to(_this2.$refs.regionLine, 0.5, {
            left: distance
          });
        }
      });
    },
    // 选择现有地址
    selectedExist: function selectedExist(item) {
      var copyExistAdd = this.existAddress;
      var prevExistAdd = {};
      copyExistAdd.forEach(function (list, index) {
        if (list.selectedAddress) {
          prevExistAdd = list;
        }

        list.selectedAddress = false;
      });
      item.selectedAddress = true;
      this.selectedExistAddress = item;
      this.$emit('selected', prevExistAdd, item, copyExistAdd);
      this.handClose();
    },
    // 关闭
    close: function close() {
      var that = this;

      var resCopy = _extends({}, this.selectedRegion);

      var res = {
        type: this.showModule,
        data: {}
      };

      if (this.showModule == 'custom') {
        var province = resCopy.province,
            city = resCopy.city,
            country = resCopy.country,
            town = resCopy.town;
        resCopy.addressIdStr = [province.id || 0, city.id || 0, country.id || 0, town.id || 0].join('_');
        resCopy.addressStr = [province.name, city.name, country.name, town.name].join('');
        res.data = resCopy;
      } else {
        res.data = this.selectedExistAddress;
      }

      this.initAddress();

      if (this.closeWay == 'self') {
        this.$emit('close', res);
      } else {
        this.$emit('closeMask', {
          closeWay: this.closeWay
        });
        this.$emit('close-mask', {
          closeWay: this.closeWay
        });
      }

      setTimeout(function () {
        that.showModule = 'type';
      }, 500);
    },
    // 手动关闭 点击叉号(cross)，或者蒙层(mask)
    handClose: function handClose() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'self';
      if (!this.closeBtnIcon) return;
      this.closeWay = type == 'cross' ? 'cross' : 'self';
      this.showPopup = false;
    },
    // 点击遮罩层关闭
    clickOverlay: function clickOverlay() {
      this.closeWay = 'mask';
    },
    // 初始化
    initAddress: function initAddress() {
      for (var i = 0; i < this.tabName.length; i++) {
        this.selectedRegion[this.tabName[i]] = {};
      }

      this.tabIndex = 0;
      this.lineAnimation();
    },
    // 选择其他地址
    switchModule: function switchModule() {
      if (this.showModule == 'exist') {
        this.showModule = 'custom';
      } else {
        this.showModule = 'exist';
      }

      this.initAddress();
      this.$emit('switchModule', {
        type: this.showModule
      });
      this.$emit('switch-module', {
        type: this.showModule
      });
    }
  }
};