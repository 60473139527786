import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import Icon from '../icon/icon.vue';
import touchMixins from '../../mixins/touch.js';
import { overlayManager } from './overlay/overlay-manager.js';
import { overlayProps } from './overlay/overlay.vue';
import { on, off } from '../../utils/event';
import '../icon/icon.scss';
var overflowScrollReg = /scroll|auto/i;
var popupProps = {
  id: {
    type: String | Number,
    default: ''
  },
  position: {
    type: String,
    default: 'center'
  },
  transition: String,
  closeable: {
    type: Boolean,
    default: false
  },
  closeIconPosition: {
    type: String,
    default: 'top-right'
  },
  closeIcon: {
    type: String,
    default: 'cross'
  },
  closeOnClickOverlay: {
    type: Boolean,
    default: true
  },
  destroyOnClose: {
    type: Boolean,
    default: false
  },
  getContainer: String,
  round: {
    type: Boolean,
    default: false
  }
};
export default {
  name: 'nut-popup',
  mixins: [touchMixins],
  components: {
    icon: Icon
  },
  props: _objectSpread(_objectSpread({}, overlayProps), popupProps),
  created: function created() {
    this.transition ? this.transitionName = this.transition : this.transitionName = "popup-slide-".concat(this.position);
  },
  mounted: function mounted() {
    if (this.value) {
      this.open();
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.value) {
      this.close();
    }
  },
  activated: function activated() {
    if (this.keepAlive) {
      this.$emit('input', true);
      this.keepAlive = false;
    }
  },
  deactivated: function deactivated() {
    if (this.value) {
      this.close();
      this.keepAlive = true;
    }
  },
  watch: {
    value: function value(val) {
      var type = val ? 'open' : 'close';
      this[type]();
    },
    position: function position(val) {
      val === 'center' ? this.transitionName = 'popup-fade' : this.transitionName = "popup-slide-".concat(this.position);
    },
    getContainer: 'portal',
    overlay: 'renderOverlay'
  },
  data: function data() {
    return {
      showSlot: true,
      transitionName: 'popup-fade-center',
      overlayInstant: null
    };
  },
  computed: {
    transitionDuration: function transitionDuration() {
      return this.duration ? this.duration + 's' : 'initial';
    }
  },
  methods: {
    open: function open() {
      if (this.opened) {
        return;
      }

      if (this.destroyOnClose) {
        this.showSlot = true;
      }

      this.opened = true;
      this.$emit('open');
      var duration = this.duration,
          overlayClass = this.overlayClass,
          overlayStyle = this.overlayStyle,
          lockScroll = this.lockScroll,
          closeOnClickOverlay = this.closeOnClickOverlay;
      var config = {
        id: this.id,
        zIndex: this.zIndex ? this.zIndex : overlayManager.getZIndex(this.id),
        duration: duration,
        overlayClass: overlayClass,
        overlayStyle: overlayStyle,
        lockScroll: lockScroll,
        closeOnClickOverlay: closeOnClickOverlay
      };
      this.renderOverlay(config);

      if (this.lockScroll) {
        on(document, 'touchstart', this.touchStart);
        on(document, 'touchmove', this.onTouchMove);

        if (!overlayManager.lockCount) {
          document.body.classList.add('nut-overflow-hidden');
        }

        overlayManager.lockCount++;
      }

      this.$el.style.zIndex = this.zIndex ? this.zIndex + 1 : overlayManager.getZIndex();
    },
    renderOverlay: function renderOverlay(config) {
      if (!this.value) {
        return;
      }

      if (this.overlay) {
        overlayManager.openModal(this, config);
      } else {
        overlayManager.closeOverlay(this);
      }
    },
    onTouchMove: function onTouchMove(event) {
      this.touchMove(event);
      var el = this.getScroller(event.target);

      var _ref = el ? el : this.$el,
          scrollHeight = _ref.scrollHeight,
          offsetHeight = _ref.offsetHeight,
          scrollTop = _ref.scrollTop;

      if (this.deltaY > 0 && scrollTop === 0 || this.deltaY < 0 && scrollTop + offsetHeight >= scrollHeight) {
        if (event.cancelable) {
          event.preventDefault();
        }
      }
    },
    getScroller: function getScroller(el) {
      var node = el;

      while (node && node.tagName !== 'HTML' && node.nodeType === 1) {
        var _window$getComputedSt = window.getComputedStyle(node),
            overflowY = _window$getComputedSt.overflowY;

        if (overflowScrollReg.test(overflowY)) {
          return node;
        }

        node = node.parentNode;
      }
    },
    close: function close() {
      var _this = this;

      if (!this.opened) {
        return;
      }

      this.$emit('close');
      this.opened = false;

      if (this.lockScroll) {
        overlayManager.lockCount--;
        off(document, 'touchstart', this.touchStart);
        off(document, 'touchmove', this.onTouchMove);

        if (!overlayManager.lockCount) {
          document.body.classList.remove('nut-overflow-hidden');
        }
      }

      if (this.destroyOnClose) {
        setTimeout(function () {
          _this.showSlot = false;
        }, this.duration * 1000);
      }

      overlayManager.closeOverlay(this);
      this.$emit('input', false);
    },
    getElement: function getElement(selector) {
      return document.querySelector(selector);
    },
    portal: function portal() {
      var getContainer = this.getContainer;
      var el = this.$el;
      var container;

      if (getContainer) {
        container = this.getElement(getContainer);
      } else if (this.$parent) {
        container = this.$parent.$el;
      }

      if (container && container !== el.parentNode) {
        container.appendChild(el);
      }
    }
  }
};
export { popupProps };