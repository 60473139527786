import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
//
//
//
//
//
export default {
  name: 'nut-row',
  props: {
    type: {
      type: String,
      default: ''
    },
    gutter: {
      type: [String, Number],
      default: ''
    },
    tag: {
      type: String,
      default: 'div'
    },
    justify: {
      type: String,
      default: 'start'
    },
    align: {
      type: String,
      default: 'flex-start'
    },
    flexWrap: {
      type: String,
      default: 'nowrap'
    }
  },
  computed: {
    getGutter: function getGutter() {
      return "".concat(this.gutter, "px");
    }
  },
  methods: {
    initCol: function initCol(slot) {
      for (var i = 0; i < slot.length; i++) {
        var tag = slot[i].tag;

        if (typeof tag == 'string') {
          if (tag.indexOf('nut-col') != -1) {
            var slotElm = slot[i].elm;
            slotElm.style.paddingLeft = this.getGutter;
            slotElm.style.paddingRight = this.getGutter;
          }
        }
      }
    },
    getClass: function getClass(prefix, type) {
      return prefix ? type ? "nut-row-".concat(prefix, "-").concat(type) : '' : "nut-row-".concat(type);
    },
    getClassObject: function getClassObject() {
      return "\n                ".concat(this.getClass('', this.type), "  \n                ").concat(this.getClass('justify', this.justify), " \n                ").concat(this.getClass('align', this.align), " \n                ").concat(this.getClass('flex', this.flexWrap), "\n                ");
    }
  },
  mounted: function mounted() {
    var slot = _toConsumableArray(this.$slots.default);

    this.initCol(slot);
  }
};