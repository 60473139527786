//
//
//
//
//
//
//
//
import Icon from './../icon/icon.vue';
export default {
  name: 'nut-button',
  props: {
    type: {
      type: String,
      default: ''
    },
    shape: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    label: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    }
  },
  components: {
    'nut-icon': Icon
  },
  computed: {
    clsStyle: function clsStyle() {
      var cls = "nut-button ".concat(this.type, " ").concat(this.shape, " \n            ").concat(this.small ? ' small' : '', " \n            ").concat(this.block ? ' block' : '', " \n            ").concat(this.label ? ' label' : '', "\n            ").concat(!this.$slots.default ? this.small ? 'no-txt-small' : 'no-txt' : '');
      return cls;
    }
  },
  methods: {
    clickHandler: function clickHandler(event) {
      // 如果是loading就阻止点击
      if (this.disabled) {
        return;
      }

      this.$emit('click', event);
    }
  }
};