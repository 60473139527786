import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Vue from 'vue';
import settings from './notify.vue'; // 扩展为类

var NotifyConstructor = Vue.extend(settings);
var timer,
    instance,
    instanceArr = [];
var defaultOptionsMap = {};
var id = '0'; // 默认传入值

var defaultOptions = {
  type: 'danger',
  showPopup: false,
  msg: '',
  color: undefined,
  background: undefined,
  duration: 3000,
  className: '',
  onClosed: null,
  onClick: null,
  onOpened: null,
  textTimer: null
}; // 当前传入值

var currentOptions = _objectSpread({}, defaultOptions); // 展示，挂载


function _showNotify() {
  instance.vm = instance.$mount();
  document.body.appendChild(instance.$el);
  Vue.nextTick(function () {
    instance.showPopup = true;
  });
}

function _getInstance(obj) {
  // 默认传递的值
  var opt = {
    id: id
  };

  _extends(opt, currentOptions, defaultOptionsMap[obj.type], obj); // console.log(opt, obj, 'obj');
  //有相同id者共用一个实例，否则新增实例


  if (opt['id'] && instanceArr[opt['id']]) {
    instance = instanceArr[opt['id']];
    instance.hide(true);
    instance = _extends(instance, opt);
  } else {
    instance = new NotifyConstructor({
      propsData: opt
    });
    instance = _extends(instance, obj);
    opt['id'] && (instanceArr[opt['id']] = instance);
  }

  _showNotify();

  return instance;
}

function errorMsg(msg) {
  if (!msg) {
    console.warn('[NutUI Toast]: msg不能为空');
    return;
  }
}

var Notify = {
  text: function text(msg) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    errorMsg(msg);
    return _getInstance(_objectSpread(_objectSpread({}, obj), {}, {
      msg: msg
    }));
  },
  primary: function primary(msg) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    errorMsg(msg);
    return _getInstance(_objectSpread(_objectSpread({}, obj), {}, {
      msg: msg,
      type: 'primary'
    }));
  },
  success: function success(msg) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    errorMsg(msg);
    return _getInstance(_objectSpread(_objectSpread({}, obj), {}, {
      msg: msg,
      type: 'success'
    }));
  },
  danger: function danger(msg) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    errorMsg(msg);
    return _getInstance(_objectSpread(_objectSpread({}, obj), {}, {
      msg: msg,
      type: 'danger'
    }));
  },
  warn: function warn(msg) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    errorMsg(msg);
    return _getInstance(_objectSpread(_objectSpread({}, obj), {}, {
      msg: msg,
      type: 'warning'
    }));
  },
  // 全局设置默认内容
  setDefaultOptions: function setDefaultOptions(type, options) {
    if (typeof type === 'string') {
      defaultOptionsMap[type] = options;
    } else {
      _extends(currentOptions, type);
    }
  },
  // 重置默认内容
  resetDefaultOptions: function resetDefaultOptions(type) {
    if (typeof type === 'string') {
      defaultOptionsMap[type] = null;
    } else {
      currentOptions = _objectSpread({}, defaultOptions);
      defaultOptionsMap = {};
    }
  }
};
export default Notify;