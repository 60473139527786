//
//
//
//
//
//
//
//
//
//
import locale from '../../mixins/locale';
export default {
  name: 'nut-step',
  mixins: [locale],
  props: {
    title: {
      type: String
    },
    content: {
      type: String
    }
  },
  data: function data() {
    return {
      currentStatus: '',
      stepNumber: 0,
      stepStatus: 'nut-step-status'
    };
  },
  methods: {},
  mounted: function mounted() {}
};