import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-stepper',
  props: {
    simple: {
      type: Boolean,
      default: true
    },
    min: {
      type: [Number, String],
      default: 0
    },
    max: {
      type: [Number, String],
      default: Infinity
    },
    step: {
      type: [Number, String],
      default: 1
    },
    readonly: {
      type: Boolean,
      default: false
    },
    transition: {
      type: Boolean,
      default: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    decimalPlaces: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      tempNum: '',
      focusing: false,
      num: this.value,
      minNum: this.min,
      showNum: true,
      showAddAnim: false,
      showReduceAnim: false,
      animNum: [this.value, this.value],
      animTranslate_add: 0,
      animTranslate_: -100,
      isLegal: true //是否合法 isLegal

    };
  },
  filters: {
    maxv: function maxv(v, min, max) {
      var val = v;
      if (val > max) val = max;
      if (val < min) val = min;
      return val;
    }
  },
  watch: {
    value: {
      handler: function handler(v, ov) {
        if (v > this.max) v = this.max;
        if (v < this.minNum) v = this.minNum;
        this.num = v > 0 ? this.fixedDecimalPlaces(v) : v;
        this.$emit('change', this.num);
      },
      immediate: true
    },
    min: {
      handler: function handler(v, ov) {
        this.isLegal = true;

        if (v < this.max) {
          this.minNum = v;
        } else {
          this.isLegal = false;
        }
      },
      immediate: true
    },
    max: {
      handler: function handler(v, ov) {
        this.isLegal = true;

        if (v <= this.min) {
          this.isLegal = false;
        }
      },
      immediate: true
    }
  },
  computed: {
    isGray: function isGray() {
      return (this.focusing ? this.tempNum : this.num) - this.step < this.min;
    }
  },
  methods: {
    focus: function focus(e) {
      if (this.readonly || !this.isLegal) return; // clear val temporary when focus, e...s

      var v = this.num;
      this.tempNum = v;
      this.minNum = ''; // this.num = '';

      this.focusing = true;
      this.$emit('focus', e, this.num);
    },
    blur: function blur(e) {
      if (this.readonly || !this.isLegal) return this.$emit('blur', e, this.num);
      var v = e.target.value;
      this.minNum = this.min;
      this.focusing = false;

      if (v) {
        if (v > this.max) v = this.max;
        if (v < this.minNum) v = this.minNum;
        this.num = v;
      } else {
        this.num = this.tempNum;
      }

      this.$emit('update:value', this.num);
      this.$emit('blur', e, this.num);
    },
    checknum: function checknum(e) {
      var v = e.target.value; // this.minNum = this.min;

      this.focusing = false;
      if (v > this.max) v = this.max;
      if (v < this.minNum) v = this.minNum;
      e.target.value = v;
      this.num = v;
    },
    numchange: function numchange(e) {
      var v = e.target.value;
      if (v > this.max) v = this.max;
      if (v < this.minNum) v = this.minNum;
      e.target.value = v;
      this.num = v;
      this.$emit('update:value', this.num);
      this.$emit('change', this.num);
    },
    fixedDecimalPlaces: function fixedDecimalPlaces(v) {
      return Number(v).toFixed(this.decimalPlaces); // .replace(/(\d+\.[^0]*)0+$/, '$1').replace(/\.$/, '')
    },
    add: function add() {
      var _this = this;

      this.num = Number(this.num);

      if (this.num <= this.max - this.step && this.max > this.minNum) {
        var _this$fixedDecimalPla = this.fixedDecimalPlaces(this.num + Number(this.step)).split('.'),
            _this$fixedDecimalPla2 = _slicedToArray(_this$fixedDecimalPla, 2),
            n1 = _this$fixedDecimalPla2[0],
            n2 = _this$fixedDecimalPla2[1];

        var fixedLen = n2 ? n2.length : 0;
        this.num = parseFloat(n1 + (n2 ? '.' + n2 : '')).toFixed(fixedLen);

        if (this.transition) {
          this.showNum = false;
          this.showAddAnim = true;
          this.showReduceAnim = false;
          this.animNum = [parseFloat(this.num - this.step).toFixed(fixedLen), this.num];
          this.animTranslate_add = -100;
          var f = this.$el.querySelector('.nut-stepper-fake');
          f.addEventListener('webkitTransitionEnd', function () {
            _this.showNum = true;
            _this.showAddAnim = false;
            _this.animTranslate_add = 0;
          });
        }

        this.$emit('update:value', this.num);
        this.$emit('add', this.num);
      } else {
        this.$emit('add-no-allow');
      }
    },
    animEnd: function animEnd() {
      // unbind
      this.showNum = true;
    },
    reduce: function reduce() {
      var _this2 = this;

      if (this.num - this.step >= this.minNum) {
        var _this$fixedDecimalPla3 = this.fixedDecimalPlaces(this.num - Number(this.step)).split('.'),
            _this$fixedDecimalPla4 = _slicedToArray(_this$fixedDecimalPla3, 2),
            n1 = _this$fixedDecimalPla4[0],
            n2 = _this$fixedDecimalPla4[1];

        var fixedLen = n2 ? n2.length : 0;
        this.num = parseFloat(n1 + (n2 ? '.' + n2 : '')).toFixed(fixedLen);

        if (this.transition) {
          this.showNum = false;
          this.showAddAnim = false;
          this.showReduceAnim = true;
          this.animNum = [this.num, this.num];
          this.animTranslate_ = 0;
          var f = this.$el.querySelector('.nut-stepper-fake-');
          f.addEventListener('webkitTransitionEnd', function () {
            _this2.showNum = true;
            _this2.showReduceAnim = false;
            _this2.animTranslate_ = -100;
          });
        }

        this.$emit('update:value', this.num);
        this.$emit('reduce', this.num);
      } else {
        this.$emit('reduce-no-allow');
      }
    }
  }
};