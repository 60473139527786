import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import nutcheckbox from '../checkbox/checkbox.vue';
import '../checkbox/checkbox.scss';
export default {
  name: 'nut-checkboxgroup',
  props: {
    name: String,
    checkBoxData: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    keys: {
      type: Object,
      default: function _default() {
        return {
          id: 'id',
          name: 'name',
          class: 'class',
          label: 'label',
          value: 'value',
          disabled: 'disabled'
        };
      }
    },
    customClass: {
      type: String,
      default: ''
    },
    label: {
      type: [String, Number, Boolean],
      default: ''
    },
    size: {
      type: [String, Number],
      default: 'base'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    animation: {
      type: Boolean,
      default: true
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      ignoreChange: false,
      checkboxValues: [],
      initialValue: []
    };
  },
  components: _defineProperty({}, nutcheckbox.name, nutcheckbox),
  watch: {
    value: function value() {
      this.init();
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      this.initialValue = this.value;
      this.checkBoxData.map(function (item) {
        if (_typeof(item) === 'object') {
          item.checked = _this.isOptionCheckedByDefault(item);
        }
      });
    },
    isObject: function isObject(obj) {
      return obj !== null && _typeof(obj) === 'object';
    },
    looseIndexOf: function looseIndexOf(arr, val) {
      for (var i = 0; i < arr.length; i++) {
        if (this.looseEqual(arr[i], val)) {
          return i;
        }
      }

      return -1;
    },
    isOptionCheckedByDefault: function isOptionCheckedByDefault(item) {
      return this.looseIndexOf(this.initialValue, item[this.keys.value] || item) > -1;
    },
    looseEqual: function looseEqual(a, b) {
      return a == b || (this.isObject(a) && this.isObject(b) ? JSON.stringify(a) === JSON.stringify(b) : false);
    },
    changeEvt: function changeEvt(args, item) {
      if (this.ignoreChange) {
        return;
      }

      var checked = args[0];
      var label = args[1];
      var e = args[2];
      var value = [];
      var itemValue = item[this.keys.value] || item;
      var i = this.looseIndexOf(this.value, itemValue);

      if (checked && i < 0) {
        value = this.value.concat(itemValue);
      }

      if (!checked && i > -1) {
        value = this.value.slice(0, i).concat(this.value.slice(i + 1));
      }

      this.$emit('input', value);
      this.$emit('change', value, label, e);
    },
    toggleAll: function toggleAll(checked) {
      if (checked === false) {
        this.$emit('input', []);
        return;
      }

      if (checked === true) {
        this.checkBoxData.map(function (item) {
          item.checked = true;
        });
      }

      if (!checked) {
        this.checkBoxData.map(function (item) {
          item.checked = !item.checked;
        });
      }

      var value = [],
          label = [];
      var resData = this.checkBoxData.filter(function (item) {
        if (item.checked) {
          value.push(item.value);
          label.push(item.label);
        }

        return item.checked;
      });
      this.$emit('input', value);
      this.$emit('change', value, label, null);
    }
  }
};