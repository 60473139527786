//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-imagepicker',
  props: {
    animation: {
      type: [Boolean],
      default: true
    },
    width: {
      type: [String, Number],
      default: 100
    },
    height: {
      type: [String, Number],
      default: 100
    },
    margin: {
      type: [String, Number],
      default: 5
    },
    max: {
      //允许上传的最大数量
      type: [Number, String],
      default: 9
    },
    accept: {
      type: [String],
      default: 'image/*'
    },
    ismultiple: {
      //是否开启多图片上传
      type: [Boolean],
      default: false
    },
    delMode: {
      //删除图片的方式，支持tap、longtap
      type: [String],
      default: 'tap'
    },
    longTapTime: {
      type: [Number],
      default: 500
    },
    autoUpload: {
      //选择完图片之后是否自动完成上传
      type: [Boolean, String],
      default: false
    },
    imgList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      timeOutEvent: 0,
      list: []
    };
  },
  mounted: function mounted() {
    this.list = this.imgList;
  },
  methods: {
    addImg: function addImg(event) {
      var self = this; //限制图片上传数量

      var file = event.target.files;
      var fileArr = Array.from(file);

      if (file.length > self.max - self.list.length) {
        fileArr = fileArr.filter(function (item, index) {
          return index < self.max - self.list.length;
        });
      }

      if (self.autoUpload) {
        //自动上传
        self.$emit('imgMsg', {
          code: 1,
          msg: fileArr
        });
        self.$emit('img-msg', {
          code: 1,
          msg: fileArr
        });
      } else {
        fileArr.forEach(function (item, index) {
          var reader = new FileReader();

          reader.onload = function (evt) {
            self.list.push({
              id: Math.random(),
              src: evt.target.result
            });
            event.target.value = '';
          };

          reader.readAsDataURL(item);
        });
        self.$emit('imgMsg', {
          code: 2,
          msg: fileArr
        });
        self.$emit('img-msg', {
          code: 2,
          msg: fileArr
        });
      }
    },
    preview: function preview(id) {
      this.$emit('imgMsg', {
        code: 4,
        msg: id
      });
      this.$emit('img-msg', {
        code: 4,
        msg: id
      });
    },
    deleteImg: function deleteImg(id) {
      this.list = this.list.filter(function (item) {
        return item.id != id;
      });
      this.$emit('update:imgList', this.list);
      this.$emit('imgMsg', {
        code: 3,
        msg: id
      });
      this.$emit('img-msg', {
        code: 3,
        msg: id
      });
    },
    touchStart: function touchStart(id) {
      var _this = this;

      // console.log(this.longTapTime);
      this.timeOutEvent = setTimeout(function () {
        _this.deleteImg(id);
      }, this.longTapTime);
      return false;
    },
    touchMove: function touchMove(id) {
      clearTimeout(this.timeOutEvent);
      return false;
    },
    touchEnd: function touchEnd(id) {
      clearTimeout(this.timeOutEvent); //清除定时器

      this.timeOutEvent = 0;
    }
  }
};